interface toLocaleDateParams {
	year?: string;
	month?: string;
	day?: string;
	hour?: string;
	minute?: string;
	second?: string;
	hour12?: boolean;
	weekday?: string;
}

export const dateFormat = function (date: number | null, params: toLocaleDateParams = { year: "numeric", month:"2-digit", day: "2-digit" }, locale: string = 'en-US'): string {
	if(date === null) {
		return '';
	}

	let d: Date = new Date(date);

	return d.toLocaleDateString(locale, params)
};

export const dateTimeFormat = function (date: number | null, params: toLocaleDateParams = { hour:"2-digit", minute: "2-digit" }, locale: string = 'en-US'): string {
	if(date === null) {
		return '';
	}

	let d: Date = new Date(date);

	return d.toLocaleTimeString(locale, params)
};
 

export const getBackgroundUrl = function (image: string) {
	return `url(${image})`
};

function transliterate(str: string): string {
	const tr = 'A B V G D E ZH Z I Y K L M N O P R S T U F H C CH SH SCH  Y  E YU YA a b v g d e zh z i y k l m n o p r s t u f h c ch sh sch  y  e yu ya e e'.split(' ');
	let result = '';
	for (let i = 0; i < str.length; ++i) {
		let cc = str.charCodeAt(i);
		result += (cc === 1025 ? 'E' : (cc >= 1040 ? tr[cc - 1040] : str[i]));
	}
	return result;
}

export const stringToUrl: (str: string) => string = (str) => {
	return transliterate(str)
		.replace('[^0-9a-z -]+', '')
		.replace(' ', '-')
		.replace('\'', '')
		.toLowerCase()
};

export function debounce<F extends Function>(func:F, wait:number):F {
	let timeoutID:number;

	Number.isFinite = Number.isFinite || function(value) {
		return typeof value === 'number' && isFinite(value);
	};

	Number.isInteger = Number.isInteger || function(value) {
		return typeof value === 'number'
			&& Number.isFinite(value)
			&& !(value % 1);
	};

	if (!Number.isInteger(wait)) {
		console.log("Called debounce without a valid number");
		wait = 300;
	}

	// conversion through any necessary as it wont satisfy criteria otherwise
	return <F><any>function(this:any, ...args: any[]) {
		clearTimeout(timeoutID);
		const context = this;

		timeoutID = window.setTimeout(function() {
			func.apply(context, args);
		}, wait);
	};
}

export function equal(a, b) {
	return JSON.stringify(a) == JSON.stringify(b);
}

