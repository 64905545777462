import {BaseUser} from "@common/react/objects/BaseUser";
import {List} from "@common/typescript/objects/List";
import {Action, Reducer} from "redux";
import {BaseApplicationState, BaseAppThunkAction} from "@common/react/store/index";
import {request} from "@common/react/components/Api";
import {addTask} from "domain-task";

export interface Comment<TUser extends BaseUser> {
	id: number,
	objectId: number, 
	objectType: string,
	text: string,
	time?: number,
	update?: number,
	parentComment?: Comment<TUser>,
	parent: number,
	level?: number,
	rating?: number,
	children?: List<Comment<TUser>>,
	childrenIds?: List<number>,
	usr: TUser,
	user: number
}

export interface ItemComments<TUser extends BaseUser> {
	root: Comment<TUser>,
	[id: number]: Comment<TUser>
}

export interface TypeComments<TUser extends BaseUser> {
	objectType: string;
	[id: number]: ItemComments<TUser>
}

export interface CommentsState<TUser extends BaseUser> {
	[objectType: string]: TypeComments<TUser>
}

export enum TypeKeys {
	RECIEVECOMMENTTREE = 'RECIEVECOMMENTTREE',
	ADDCOMMENT = 'ADDCOMMENT',
	UPDATECOMMENT = 'UPDATECOMMENT',
	DELETECOMMENT = 'DELETECOMMENT',
}

interface ReceiveTreeAction<TUser extends BaseUser> {
	type: TypeKeys.RECIEVECOMMENTTREE
	item: Comment<TUser>,
	storageName: string
}

interface AddItem<TUser extends BaseUser> {
	type: TypeKeys.ADDCOMMENT,
	item: Comment<TUser>,
	storageName: string,
	insertBefore?: boolean
}

interface UpdateItem<TUser extends BaseUser> {
	type: TypeKeys.UPDATECOMMENT,
	item: Comment<TUser>,
	storageName: string
}

interface DeleteItem<TUser extends BaseUser> {
	type: TypeKeys.DELETECOMMENT,
	item: Comment<TUser>,
	storageName: string
}

type KnownPageAction<TUser extends BaseUser> = ReceiveTreeAction<TUser> | AddItem<TUser> | UpdateItem<TUser> | DeleteItem<TUser>;

export function getActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> () {
	return {
		loadTree: (objectType: string, objectId: number, additionalParams = {}, store: string = 'comments'): BaseAppThunkAction<KnownPageAction<TUser>, TUser, TApplicationState> => (dispatch, getState) => {
			let fetchTask = request(
				'commentsList', {
					objectType: objectType,
					objectId: objectId,
					...additionalParams
				}, getState()
			).then(function (data) {
				dispatch({type: TypeKeys.RECIEVECOMMENTTREE, item: data as Comment<TUser>, storageName: store});
			});

			addTask(fetchTask);
			
			return fetchTask;
		},
		updateComment: (comment: Comment<TUser>, store: string = 'comments'): BaseAppThunkAction<KnownPageAction<TUser>, TUser, TApplicationState> => (dispatch, getState) => {
			let fetchTask = request(
				'comment',
				comment,
				getState()
			).then(function (data) {
				dispatch({type: TypeKeys.UPDATECOMMENT, item: data as Comment<TUser>, storageName: store});
			});

			addTask(fetchTask);

			return fetchTask;
		},
		simpleUpdateComment: (comment: Comment<TUser>, store: string = 'comments'): BaseAppThunkAction<KnownPageAction<TUser>, TUser, TApplicationState> => (dispatch, getState) => {
			dispatch({type: TypeKeys.UPDATECOMMENT, item: comment, storageName: store});
		},
		addComment: (comment: Comment<TUser>, insertBefore?: boolean, store: string = 'comments'): BaseAppThunkAction<KnownPageAction<TUser>, TUser, TApplicationState> => (dispatch, getState) => {
			let fetchTask = request(
				'comment',
				comment,
				getState()
			).then(function (data) {
				dispatch({ type: TypeKeys.ADDCOMMENT, item: data as Comment<TUser>, insertBefore, storageName: store});
			});

			addTask(fetchTask);

			return fetchTask;
		},
		deleteComment: (comment: Comment<TUser>, store: string = 'comments'): BaseAppThunkAction<KnownPageAction<TUser>, TUser, TApplicationState> => (dispatch, getState) => {
			let fetchTask = request(
				'comment',{
					...comment, 
					deleted: true
				},
				getState()
			).then(function (data) {
				dispatch({type: TypeKeys.DELETECOMMENT, item: data as Comment<TUser>, storageName: store});
			});

			addTask(fetchTask);

			return fetchTask;
		},
	}
}

export function getReducer<TUser extends BaseUser>(storageName: string = 'comments'):Reducer<CommentsState<TUser>> {
	return (state: CommentsState<TUser>, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction<TUser>;
		
		if (!(action.type in TypeKeys)){
			return state || {};
		}

		if (!action.storageName || action.storageName === storageName) {

			let typeComments = state[action.item.objectType]
				? {...state[action.item.objectType]}
				: {objectType: action.item.objectType};
			let itemComments = typeComments[action.item.objectId]
				? {...typeComments[action.item.objectId]}
				: {root: {...action.item}};

			switch (action.type) {
				case TypeKeys.RECIEVECOMMENTTREE:
					const processNextComment = (nextComment: Comment<TUser>) => {
						if (nextComment.id > 0) {
							itemComments[nextComment.id] = nextComment;
						}

						if (nextComment.children) {
							nextComment.childrenIds = {
								...nextComment.children,
								list: nextComment.children.list.map(child => child.id)
							};

							nextComment.children.list.forEach(child => processNextComment({...child}));

							nextComment.children = undefined;
						}
					};

					processNextComment(itemComments.root);

					return {
						...state,	//all objectTypes
						[action.item.objectType]: {
							...typeComments,	//all objectIds
							[action.item.objectId]: itemComments as ItemComments<TUser>
						}
					} as any;
				case TypeKeys.ADDCOMMENT: {
					let parentComment = action.item.parent > 0
						? {...itemComments[action.item.parent]}
						: {...itemComments.root};

					if (!parentComment.childrenIds) {
						parentComment.childrenIds = {
							offset: 0,
							count: 1,
							execution: 0,
							list: [action.item.id]
						}
					} else {
						parentComment.childrenIds = {
							offset: 0,
							count: parentComment.childrenIds.count + 1,
							execution: 0,
							list: action.insertBefore ? [action.item.id, ...parentComment.childrenIds.list] : [...parentComment.childrenIds.list, action.item.id]
						}
					}

					return {
						...state,	//all objectTypes
						[action.item.objectType]: {
							...typeComments,	//all objectIds
							[action.item.objectId]: {
								...itemComments,
								[action.item.id]: action.item,
								[action.item.parent || 'root']: parentComment
							}
						}
					}
				}
				case TypeKeys.UPDATECOMMENT:
					return {
						...state,	//all objectTypes
						[action.item.objectType]: {
							...typeComments,	//all objectIds
							[action.item.objectId]: {
								...itemComments,
								[action.item.id]: {
									...itemComments[action.item.id],
									...action.item
								}
							}
						}
					};
				case TypeKeys.DELETECOMMENT: {
					let parentComment = action.item.parent > 0
						? {...itemComments[action.item.parent]}
						: {...itemComments.root};

					let parentCommentChildren = parentComment.childrenIds as List<number>;

					let oldChildrenList = parentCommentChildren.list;

					let newChildrenList = oldChildrenList.slice();
					newChildrenList.splice(oldChildrenList.indexOf(action.item.id), 1);

					parentComment.childrenIds = {
						offset: 0,
						count: parentCommentChildren.count - 1,
						execution: 0,
						list: newChildrenList
					};

					return {
						...state,	//all objectTypes
						[action.item.objectType]: {
							...typeComments,	//all objectIds
							[action.item.objectId]: {
								...itemComments,
								[action.item.id]: undefined,
								[action.item.parent || 'root']: parentComment
							}
						}
					};
				}
				default:
					const exhaustiveCheck: never = action;
			}

			return state || {
				isLoading: false, params: {}, items: []
			};
		} else {
			return state;
		}
	}
}