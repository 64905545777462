import * as React from 'react';

interface ButtonProps {
	type?: 'submit' | 'button';
	className?: string;
	isLoading?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	title?: string;
	disabled?: boolean;
	other?: object;
}

const Button: React.SFC<ButtonProps> = 
	({
		 type = 'submit' ,
		 className = 'btn btn-primary', 
		 isLoading, 
		 onClick, 
		 children, 
		 title, 
		 disabled = false, 
		 other = undefined
	}) => {
		return <button 
			{...other} 
			type={type} 
			disabled={isLoading || disabled} 
			className={`button-component ${className} ${isLoading ? 'isLoading' : ''}`} 
			onClick={onClick}
			title={title}
		>
			<span>{children}</span>
		</button>;
	};

export default Button;