import {addTask} from "domain-task";
import {request} from "@app/components/Api";
import {ApplicationState, AppThunkAction} from "@app/store/index";
import {Action, Reducer} from "redux";
import InjuryCounter from "@app/objects/InjuryCounter";
import {BaseParams} from "@common/typescript/objects/BaseParams";
import {equal} from "@common/typescript/Utils";

export interface ReportsState {
	injuryCounters: InjuryCounter;
	params: BaseParams;
}

export enum TypeKeys {
	RECEIVEINJURYCOUNTERS = "RECEIVEINJURYCOUNTERS"
}

interface RecieveInjuryCountersAction {
	type: TypeKeys.RECEIVEINJURYCOUNTERS;
	injuryCounters: InjuryCounter;
	params: BaseParams;
}

type KnownPageAction = RecieveInjuryCountersAction;

export const actionCreators = {
	reqInjuryCounters: (filters = {}): AppThunkAction<KnownPageAction> => (dispatch, getState) => {
		const storeState = (getState() as ApplicationState).injuryReports;
		
		if (!equal(storeState.params, filters)) {
			let fetchTask = request<any>('getInjuryCounters', filters
			, getState()).then(function (data) {
				dispatch({type: TypeKeys.RECEIVEINJURYCOUNTERS, injuryCounters: data, params: filters})
			});

			addTask(fetchTask);
		}
	}
};

export const reducer: Reducer<ReportsState> = (state: ReportsState, incomingAction: Action) => {
	const action = incomingAction as KnownPageAction;

	switch (action.type) {
		case TypeKeys.RECEIVEINJURYCOUNTERS:
			return {...state, injuryCounters: action.injuryCounters, params: action.params};
		default:
			const exhaustiveCheck: any = action;
	}

	return state || {
		injuryCounters: null
	};
};