import { ApplicationState } from '@app/store';
import { fetch } from 'domain-task';
import { request as baseRequest } from "@common/react/components/Api";
import { allRequest as allBaseRequest } from "@common/react/components/Api";
import {User} from "@app/objects/User";

export function request<T>(type: string, data?: any, state?: ApplicationState) {
	return baseRequest<T, User, ApplicationState>(type, data,  state)
}

export function allRequest(url: string, method: string, data?: any, state?: ApplicationState) {

    return fetch(url, {
        credentials: 'same-origin',
        method: method,
        headers: {
            'Content-type': 'application/json; charset=utf-8',
            Cookie: `session=${state ? state.login.session : ''}`
        },
        body: data != null ? JSON.stringify(data) : null
    }).then(response => response.json())
        .then((data) => {

            return data;
        }).catch((error: Error) => {
            console.log(error);
            throw error;
        });
}