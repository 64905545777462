import {BaseUser} from "@common/react/objects/BaseUser";
import {List} from "@common/typescript/objects/List";
import {Action, Reducer} from "redux";
import {BaseApplicationState, BaseAppThunkAction} from "@common/react/store/index";
import {request} from "@common/react/components/Api";
import {addTask} from "domain-task";
import {Chat, ChatMessage, ChatUser} from "@app/objects/Chat";
import {User} from "@app/objects/User";
import {ApplicationState} from "@app/store/index";

export interface ChatsState {
	chats: List<Chat>;
	currentChat: Chat | null;
	isLoading: boolean;
	isMessagesLoading: boolean;
	isMessagesLazy: boolean;
	messages: ChatMessagesState;
}

interface ChatMessagesState {
	[id: number]: List<ChatMessage>
}

export enum TypeKeys {
	RECIEVECHATS = 'RECIEVECHATS',
	REQUESTCHATS = 'REQUESTCHATS',
	ADDCHAT = 'ADDCHAT',
	UPDATECHAT = 'UPDATECHAT',
	SELECTCHAT = 'SELECTCHAT',
	DELETECHAT = 'DELETECHAT',
	REQUESTMESSAGES = 'REQUESTMESSAGES',
	RECIEVEMESSAGES = 'RECIEVEMESSAGES',
	RECIEVEMOREMESSAGES = 'RECIEVEMOREMESSAGES',
	ADDMESSAGE = 'ADDMESSAGE',
	UPDATEMESSAGE = 'UPDATEMESSAGE',
}

interface ReceiveChatsAction {
	type: TypeKeys.RECIEVECHATS
	items: List<Chat>,
}

interface RequestChatsAction {
	type: TypeKeys.REQUESTCHATS;
}

interface RequestChatMessagesAction {
	type: TypeKeys.REQUESTMESSAGES;
}

interface RecieveChatMessagesAction {
	type: TypeKeys.RECIEVEMESSAGES;
	messages: List<ChatMessage>;
	chatId: number;
}

interface RecieveChatMoreMessagesAction {
	type: TypeKeys.RECIEVEMOREMESSAGES;
	messages: List<ChatMessage>;
	chatId: number;
}

interface AddChatAction {
	type: TypeKeys.ADDCHAT;
	chat: Chat
}

interface AddMessageAction {
	type: TypeKeys.ADDMESSAGE;
	message: ChatMessage,
	increase: boolean
}

interface UpdateMessageAction {
	type: TypeKeys.UPDATEMESSAGE;
	message: ChatMessage
}

interface UpdateChatAction {
	type: TypeKeys.UPDATECHAT;
	chat: Chat
}

interface SelectChatAction {
	type: TypeKeys.SELECTCHAT;
	chat: Chat
}

interface DeleteChatAction {
	type: TypeKeys.DELETECHAT;
	chatId: number
}

type KnownPageAction = 
	ReceiveChatsAction 
	| RequestChatsAction 
	| RequestChatMessagesAction 
	| RecieveChatMessagesAction 
	| AddChatAction 
	| AddMessageAction 
	| UpdateMessageAction 
	| UpdateChatAction
	| SelectChatAction
	| RecieveChatMoreMessagesAction 
	| DeleteChatAction;

export interface ChatsActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	loadChats: (onLoad?: (data: List<Chat>) => void) => BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
	loadMessages: (chatId: number, loadMore: boolean, count?: number) => BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
	addChat: (chat: Chat) => void,
	removeChat: (chatId: number) => void,
	updateChat: (chat: Chat) => void,
	addMessage: (message: ChatMessage, increaseCounter: boolean) => void,
	updateMessage: (message: ChatMessage) => void,
	selectChat: (chat: Chat) => void,
	changeChatCounter: (chatId: number, value: number) => void,
	addUserToChat: (chatUser: ChatUser) => void,
	removeUserFromChat: (chatUser: ChatUser) => void
}

const findChat = (chats: Chat[], chatId: number): Chat | undefined => chats.find( (item: Chat) => item.id === chatId);

export function getActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> () {
	return {
		loadChats: (onLoad?: (data: List<Chat>) => void): BaseAppThunkAction<KnownPageAction, User, ApplicationState> => (dispatch, getState) => {
			let fetchTask = request(
				'chatList', {
				}, getState()
			).then(function (data) {
				dispatch({type: TypeKeys.RECIEVECHATS, items: data as List<Chat>});
				onLoad && onLoad(data as List<Chat>);
			});

			dispatch({type: TypeKeys.REQUESTCHATS});

			addTask(fetchTask);
			
			return fetchTask;
		},
		loadMessages: (chatId: number, loadMore: boolean, count = 20): BaseAppThunkAction<KnownPageAction, User, ApplicationState> => (dispatch, getState) => {
			const state = getState(),
				messages = state.chats.messages[chatId];
			
			if(!messages || loadMore) {
				let fetchTask = request(
					'chatMessageList', {
						chatId: chatId,
						count: count,
						offset: loadMore ? messages.offset + count : 0
					}, getState()
				).then(function (data) {
					const list = data as List<ChatMessage>;
					
					list.list.reverse();
					
					if(loadMore) {
						dispatch({type: TypeKeys.RECIEVEMOREMESSAGES, messages: list, chatId: chatId});
					} else {
						dispatch({type: TypeKeys.RECIEVEMESSAGES, messages: list, chatId: chatId});
					}
				});
		
				dispatch({type: TypeKeys.REQUESTMESSAGES});
				
				addTask(fetchTask);

				return fetchTask;
			}
		},
		addChat: (chat: Chat) => (dispatch, getState) => {
			dispatch({type: TypeKeys.ADDCHAT,  chat: chat});
		},
		changeChatCounter: (chatId: number, value: number) => (dispatch, getState) => {
			const state = getState();
			const chat = findChat(state.chats.chats.list ,chatId);
			
			if(chat)
				dispatch({type: TypeKeys.UPDATECHAT, chat: {...chat, unviewedMessagesCount: chat.unviewedMessagesCount + value}});
		},
		updateChat: (chat: Chat) => (dispatch, getState) => {
			dispatch({type: TypeKeys.UPDATECHAT, chat: chat});
		},
		addMessage: (message: ChatMessage, increaseCounter: boolean) => (dispatch, getState) => {
			const state = getState();
			if(state.chats.messages[message.chatId]) {
				dispatch({type: TypeKeys.ADDMESSAGE, message: message, increase: increaseCounter});
			}
		},
		updateMessage: (message: ChatMessage) => (dispatch, getState) => {
			dispatch({type: TypeKeys.UPDATEMESSAGE, message: message});
		},
		selectChat: (chat: Chat) => (dispatch, getState) => {
			dispatch({type: TypeKeys.SELECTCHAT, chat: chat});
		},
		removeChat: (chatId: number) =>(dispatch, getState) => {
			dispatch({type: TypeKeys.DELETECHAT, chatId: chatId});
		},
		addUserToChat: (chatUser: ChatUser) => (dispatch, getState) => {
			const state: ApplicationState = getState();
			const chat = findChat(state.chats.chats.list, chatUser.chatId);

			if(chat) {
				if(chat.contactsIds.indexOf(chatUser.userId) === -1) {
					const newChatInfo = {
						contacts: [...chat.contacts, chatUser.user],
						contactsIds: [chat.contactsIds ? {...chat.contactsIds} : [], chatUser.userId]
					};

					dispatch({type: TypeKeys.UPDATECHAT, chat: {...chat, ...newChatInfo}});
				}
			} else if(chatUser.chat) {
				dispatch({type: TypeKeys.ADDCHAT, chat: chatUser.chat})
			}
		},
		removeUserFromChat: (chatUser: ChatUser) => (dispatch, getState) => {
			const state: ApplicationState = getState();
			const chat = findChat(state.chats.chats.list, chatUser.chatId);

			if(chat){
				const newChatInfo = {
					contacts: chat.contacts.filter((contact: User) => contact.id !== chatUser.userId),
					contactsIds: chat.contactsIds.filter((id: number) => id !== chatUser.userId)
				};

				dispatch({type: TypeKeys.UPDATECHAT, chat: {...chat, ...newChatInfo}});
			}
		}
	}
}

export function getReducer<TUser extends BaseUser>(storageName: string = 'chats'): Reducer<ChatsState> {
	return (state: ChatsState, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction;
		
		if (!(action.type in TypeKeys)){
			return state || {
				isLoading: false,
				chats: {list: [], count: 0, offset: 0, execution: 0}, 
				messages: {}
			};
		}

		let messages;
			
		switch (action.type) {
			case TypeKeys.REQUESTCHATS:
				return {...state, isLoading: true};
			case TypeKeys.REQUESTMESSAGES:
				return {...state, isMessagesLoading: true};
			case TypeKeys.ADDCHAT:
				const newChats = {...state.chats, count: state.chats.count, list: [action.chat, ...state.chats.list]};
				return {...state, chats: newChats};
			case TypeKeys.UPDATECHAT:
				return {
					...state, 
					chats: {...state.chats, list: state.chats.list.map((chat: Chat) => chat.id === action.chat.id ? action.chat : chat)},
					currentChat: state.currentChat && state.currentChat.id === action.chat.id ? action.chat : state.currentChat
				};
			case TypeKeys.SELECTCHAT:
				return {...state, currentChat: action.chat};
			case TypeKeys.DELETECHAT:
				return {...state, chats: {
						...state.chats,
						count: state.chats.count - 1,
						list: state.chats.list.filter((chat: Chat) => chat.id !== action.chatId)
				}};
			case TypeKeys.RECIEVECHATS:
				return {...state, isLoading: false, chats: action.items};
			case TypeKeys.RECIEVEMESSAGES:
				return {...state, isMessagesLoading: false, messages: {
						...state.messages,
						[action.chatId]: action.messages 
				}};
			case TypeKeys.RECIEVEMOREMESSAGES:
				return {...state, isMessagesLoading: false, messages: {
						...state.messages,
						[action.chatId]: {
							...state.messages[action.chatId],
							list: [...action.messages.list, ...state.messages[action.chatId].list],
							offset: action.messages.offset
						}
					}};
			case TypeKeys.ADDMESSAGE: 
				messages = state.messages[action.message.chatId];
				
				const newList = [...messages.list, action.message];
				
				const chatIndex = state.chats.list.findIndex((item: Chat) => item.id == action.message.chatId);
				
				const chat = state.chats.list[chatIndex];
				
				return {
					...state, 
					chats: {
						...state.chats,
						list: [
							{
								...chat,
								messages: {...chat.messages, list: [action.message]},
								unviewedMessagesCount: chat.unviewedMessagesCount + (action.increase ? 1 : 0)
							},
							...state.chats.list.slice(0, chatIndex),
							...state.chats.list.slice(chatIndex + 1),
						]
					},
					messages: {
						...state.messages,
						[action.message.chatId]: {...messages, list: newList, count: state.messages[action.message.chatId].count + 1}
					}
				};
			case TypeKeys.UPDATEMESSAGE:
				messages = state.messages[action.message.chatId];
				
				return {
					...state,
					isMessagesLoading: false, 
					messages: {
						...state.messages,
						[action.message.chatId]: {
							...messages,
							list: messages.list.map((item: ChatMessage) => {
								if(action.message.id === item.id) {
									return action.message
								}
								
								return item;
							})
						}
					}
				};
				
		}
			
		return state || {
			isLoading: false,
			isMessagesLoading: false,
			isMessagesLazy: false,
			chats: {list: [], count: 0, offset: 0, execution: 0},
			messages: {}
		};
		
		
	}
}