import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { request } from '@app/components/Api';
import {List} from "@common/typescript/objects/List";
import {Injury} from "@app/objects/Injury";
import InjuryCounter from "@app/objects/InjuryCounter";
import {InjuryFilter} from "@app/objects/InjuryFilter";


export interface DashboardState {
	isLoading: boolean;
	counters: CountersResponse;
	cases: List<Injury>,
	injuryCounters: InjuryCounter
}

export interface CountersResponse {
	users?: number;
	employees?: number;
	companies?: number;
	unreadEmails?: number;
	newInjury: number;
	inProgressInjury: number;
	followUpInjury: number;
	pendingInjury: number;
	closedInjury: number;
	emails: number;
	overDue: number;
	aging: number;
	unreadStatusReports: number;
	voiceMessages: number;
	rtw: number;
}


export enum TypeKeys {
	REQUESTCOUNTERS = "REQUESTCOUNTERS",
	RECEIVECOUNTERS = "RECEIVECOUNTERS",
	RECEIVEINJURYCOUNTERS = "RECEIVEINJURYCOUNTERS"
}

interface ReceiveCountersAction {
    type: TypeKeys.RECEIVECOUNTERS;
	counters: CountersResponse;
}

interface RequestCountersAction {
	type: TypeKeys.REQUESTCOUNTERS;
}

interface RecieveInjuryCountersAction {
	type: TypeKeys.RECEIVEINJURYCOUNTERS;
	injuryCounters: InjuryCounter;
}


type KnownPageAction = ReceiveCountersAction | RequestCountersAction | RecieveInjuryCountersAction;

export const actionCreators = {
	reqCounters: (): AppThunkAction<KnownPageAction> => (dispatch, getState) => {
		
		let fetchTask = request<any>('counters', {
		}, getState()).then(function (data) {
			dispatch({ type: TypeKeys.RECEIVECOUNTERS, counters: data });
		});

		addTask(fetchTask);
		dispatch({ type: TypeKeys.REQUESTCOUNTERS });
	},
	reqInjuryCounters: (params: Partial<InjuryFilter>): AppThunkAction<KnownPageAction> => (dispatch, getState) => {
		
		let fetchTask = request<any>('getInjuryCounters', {filter: params}, getState()).then(function (data) {
			dispatch({ type: TypeKeys.RECEIVEINJURYCOUNTERS, injuryCounters: data})
		});

		addTask(fetchTask);
	}
};

export const reducer: Reducer<DashboardState> = (state: DashboardState, incomingAction: Action) => {
	const action = incomingAction as KnownPageAction;
	
	switch (action.type) {
		case TypeKeys.REQUESTCOUNTERS:
			return {...state, isLoading: true};
		case TypeKeys.RECEIVECOUNTERS:
			return {...state, isLoading: false, counters: action.counters};
		case TypeKeys.RECEIVEINJURYCOUNTERS:
			return {...state, injuryCounters: action.injuryCounters};
		default:
			const exhaustiveCheck: never = action;
	}

	return state || {
		isLoading : false,
		counters: {
			users: 0,
			employees: 0,
			companies: 0,
			unreadEmails: 0,
			newInjury: 0,
			inProgressInjury: 0,
			followUpInjury: 0,
			pendingInjury: 0,
			closedInjury: 0,
			emails: 0,
			overDue: 0,
			aging: 0,
			unreadStatusReports: 0,
			voiceMessages: 0,
			rtw: 0,
		},
		injuryCounters: {
			totalReferrals: 0,
			totalEstimatedIndemnitySavings: 0,
			averagePlacementDuration: 0,
			averageTimeToPlacement: 0,
			totalDaysWorked: 0,
			totalHoursWorked: 0,
			totalDaysInProgram: 0,
			averageDaysInProgram: 0,
			referraldate: null,
			placementdate: null,
			actualStartDate:null

		}
	};
};