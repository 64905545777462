import * as React from 'react';

import AutoComplete, {AutoCompleteProps} from 'antd/lib/auto-complete';
import {SelectValue} from 'antd/es/select';

import { request } from '@common/react/components/Api';
import {List} from '@common/typescript/objects/List';
import {debounce} from '@common/typescript/Utils';
import {getPopupContainer} from '@common/react/components/Utils/Utils';

export const Option = AutoComplete.Option;

interface AutocompleteState {
	items: any[];
	value: string;
	isLoading: boolean;
}

interface AutocompleteProps {
	type: string;
	onSelect: (value: string, option?: any) => void;
	onChange?: (value: string) => void;
	renderOption?: any;
	params?: any;
	paramName?: string;
    min?: number;
    value: string ;
	isClear?: boolean;
	antdProps?: AutoCompleteProps;
	loadOnFocus?: boolean;
	disabled?: boolean;
	placeholder?: string;
	onExtraRender?: (state, props) => void;
	loaderMarkup?: JSX.Element;
	updateAfterSelect?: boolean;
}

export default class Autocomplete extends React.Component<AutocompleteProps, AutocompleteState> {
	public static defaultProps: Partial<AutocompleteProps> = {
		renderOption: (item) => {
			const itemProps = {item: item};

			return <Option key={item.id} value={`${item.id}`} title={item.name} {...itemProps}>{item.name}</Option>;
		},
		value: '',
		loaderMarkup: <div className="la-ball-clip-rotate-pulse"><div/><div/></div>
	};

	constructor(props: AutocompleteProps) {
		super(props);

		this.state = {
			items: [],
			value: this.props.value || '',
			isLoading: false
		};

		this.loadItems = debounce(this.loadItems.bind(this), 300);
		this.onSearchHandler = this.onSearchHandler.bind(this);
	}

	componentWillReceiveProps(nextProps: AutocompleteProps) {
		if (nextProps.value !== this.props.value) {
			this.setState({
				value: nextProps.value
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.value !== this.props.value ||
			nextProps.disabled !== this.props.disabled ||
			nextState.value !== this.state.value ||
			nextState.items !== this.state.items || 
			nextState.isLoading !== this.state.isLoading;
	}

	onChange = (value: SelectValue) => {
		this.setState({
			value: value as string
		});

		if (this.props.onChange) this.props.onChange(value as string);
	}

	onSelect = (value, option) => {
		this.props.onSelect && this.props.onSelect(value, option);
		
		if (this.props.isClear) {
			setTimeout(() => {
				this.setState({
					value: ''
				});
			}, 0);
		} else {
			setTimeout(() => {
				this.setState({
					items: []
				});
			}, 0);
		}
		
		if (this.props.updateAfterSelect) {
			setTimeout(() => this.loadItems(this.state.value), 0);
		} 
	}

	loadItems(value: string) {
		const {renderOption, type, params, paramName} = this.props;
		
		this.setState({isLoading: true});

		request(type, { ...params, [paramName || 'text']: value }).then((response) => {
			
			this.setState({
				isLoading: false,
				items: (response as List<any>).list.map(renderOption)
			});
		});
	}

	onSearchHandler (value: string) {
		const { min = 3, loadOnFocus } = this.props;

		if (value.length >= min || loadOnFocus) {
			this.loadItems(value);
		} else {
			this.setState({
				items: []
			});
		}
	}

	onFocus = () => {
		if (this.state.value === '') {
			this.onSearchHandler('');
		}
	}

	render(): JSX.Element {
		return <React.Fragment>
			<div className={`autocomplete-component ${this.props.disabled ? 'autocomplete-component_disabled' : ''}`}>
				{this.state.isLoading && <div className="autocomplete-component__loader ">{this.props.loaderMarkup}</div>}
				<AutoComplete
					onChange={this.onChange}
					dataSource={this.state.items}
					allowClear={true}
					onSelect={this.onSelect}
					onFocus={this.props.loadOnFocus ? this.onFocus : undefined}
					onSearch={this.onSearchHandler}
					value={this.state.value}
					optionLabelProp="title"
					disabled={this.props.disabled}
					placeholder={this.props.placeholder}
					getPopupContainer={getPopupContainer}
					{...this.props.antdProps}
				/>
			</div>
			{this.props.onExtraRender && this.props.onExtraRender(this.state, this.props)}
		</React.Fragment>;
	}
}