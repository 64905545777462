import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import {ApplicationState, AppThunkAction} from './';
import { request } from '@app/components/Api';
import {BaseAppThunkAction} from "@common/react/store";
import {User} from "@app/objects/User";
import {WithDeleted} from "@common/typescript/objects/WithDeleted";

export interface GlobalSettingsState {
	isLoading: boolean;
	settings: GlobalSettingsResponse;
	isSaving: boolean;
}

export interface GlobalSettingsResponse extends WithDeleted {
    sendAlertMails: boolean;
    daylightSaving: boolean;
    enableAutoReply: boolean;
    allowInternalToCloseEmail: boolean;
    autoReplyMessage: string;
    signature: string;
    highPriorityPeriod: number;
    lowPriorityPeriod: number;
}

export enum TypeKeys {
	REQUESTSETTINGS = "REQUESTSETTINGS",
	RECEIVESETTINGS = "RECEIVESETTINGS",
	SETSETTINGSSAVING = "SETSETTINGSSAVING",
}

interface ReceiveAction {
	type: TypeKeys.RECEIVESETTINGS;
	settings: GlobalSettingsResponse;
}

interface RequestAction {
	type: TypeKeys.REQUESTSETTINGS;
}

interface SetSavingAction {
	type: TypeKeys.SETSETTINGSSAVING;
	value: boolean;
}

type KnownAction = ReceiveAction | RequestAction | SetSavingAction;


export interface GlobalSettingsActionsCreators {
	reqSettings: () => BaseAppThunkAction<KnownAction, User, ApplicationState>
	saveSettings: (data: GlobalSettingsResponse) => BaseAppThunkAction<KnownAction, User, ApplicationState>
}

export const actionCreators = {
	reqSettings: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let fetchTask = request<GlobalSettingsResponse>('getSettings', {
		}, getState()).then(function (data) {
			dispatch({ type: TypeKeys.RECEIVESETTINGS, settings: data });
		});

		addTask(fetchTask);
		dispatch({ type: TypeKeys.REQUESTSETTINGS });
	},
	saveSettings: (data: GlobalSettingsResponse):  AppThunkAction<KnownAction> => (dispatch, getState) => {
		request<GlobalSettingsResponse>('settings', data, getState()).then(function (data) {
			dispatch({ type: TypeKeys.SETSETTINGSSAVING, value: false });
		});

		dispatch({ type: TypeKeys.SETSETTINGSSAVING, value: true });
	}
};

export const reducer: Reducer<GlobalSettingsState> = (state: GlobalSettingsState, incomingAction: Action) => {
	const action = incomingAction as KnownAction;

	switch (action.type) {
		case TypeKeys.REQUESTSETTINGS:
			return {...state, isLoading: true};
		case TypeKeys.SETSETTINGSSAVING:
			return {...state, isSaving: action.value};
		case TypeKeys.RECEIVESETTINGS:
			return {...state, isLoading: false, settings: action.settings};
		default:
			const exhaustiveCheck: never = action;
	}

	return state || {
		isLoading : false,
		settings: {
			sendAlertMails: false
		}
	};
};