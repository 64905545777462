import * as React from 'react';

const year = new Date().getFullYear();

const Footer: React.SFC<{}> = () => {
	return <footer className="site-footer">
		<div className="site-footer__copyright">
			© 2014 - {year} VARZIS
		</div>
	</footer>;
};

export default Footer;