// todo remove this file

import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';
import { AppThunkAction } from './';
import { request } from '@app/components/Api';



export interface RecoverState {
	isLoading: boolean;
	success: boolean;
	//session: string;
	//user: User | null;
}

export enum TypeKeys {
	REQUESTRECOVER = "REQUESTRECOVER",
	RECEIVERECOVER = "RECEIVERECOVER"
}

interface RequestAction {
	type: TypeKeys.REQUESTRECOVER;
}

interface ReceiveAction {
	type: TypeKeys.RECEIVERECOVER;
	//session: string;
}

type KnownAction = RequestAction | ReceiveAction;

export const actionCreators = {
	recover: (login: string, captcha: string, code: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		if (!getState().recover.isLoading) {
			let fetchTask = request<any>('recover', {
				email: login,
				captcha: captcha,
				code: code
				//path: '/recover'
			}).then(function (data) {
				dispatch({ type: TypeKeys.RECEIVERECOVER });
			});

			addTask(fetchTask);
			dispatch({ type: TypeKeys.REQUESTRECOVER });
		}
	}
};

export const reducer: Reducer<RecoverState> = (state: RecoverState, action: KnownAction) => {
	switch (action.type) {
		case TypeKeys.REQUESTRECOVER:
			return { isLoading: true, success: state.success };
		case TypeKeys.RECEIVERECOVER:
			return { isLoading: false, success: true };
		default:
			const exhaustiveCheck: never = action;
	}

	return state || { isLoading: false, success: false };
};