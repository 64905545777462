import {User} from "@app/objects/User";

import {dateFormat as baseDateFormat} from '@common/typescript/Utils';

import * as moment from "moment";

export function dateFormat(value: number, withTime?: boolean) {
	if(withTime) {
		return baseDateFormat(value, { year: "numeric", month:"2-digit", day: "2-digit", minute: "2-digit", hour: "2-digit"})
	} else {
		return baseDateFormat(value, { year: "2-digit", month:"2-digit", day: "2-digit" })
	}
}

export const getAvatar: (user: User) => string = (user) => {
	return `${user.avatar ? user.avatar : require('@images/avatar.jpg')}`
};

export const phoneMask = [ '+', '1' , ' ' ,'(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const phoneReplace = (phone: string | null | undefined): string => phone ?  phone.replace(/[\(\)\-\s]/g, "") : '';

export const phoneFormat = (phone: string | null | undefined): string => phone ? phone.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '+1 ($1) $2-$3') : '';

const getHoursAndMinutes = (value: number): {hours: number, minutes: number} => {
	const hours = (value / 60);
	return {
		hours: Math.floor(hours),
		minutes: Math.round((hours - Math.floor(hours)) * 60)
	}
};


export const minutesToHours = (value: number, minimized?: boolean): string => {
	const {hours, minutes} = getHoursAndMinutes(value);
	if(minimized) {
        return addCommaStr((minutes > 0 ? (value / 60).toFixed(0) : (hours + ''))) ;// + (hours > 1 ? ' hours' : ' hour') 
	} 
	
	return hours >= 1 ? (hours + (hours > 1 ? ' hours' : ' hour') + (minutes >= 1 ? (' and ' + minutes + (minutes > 1 ? ' minutes' : ' minute')) : '')) : (minutes + (minutes > 1 ? ' minutes' : ' minute'));
};

export const timecalculation = (referaldate: Date, placement: Date): number => {

	var d1msecs = new Date(referaldate).getTime(); // get milliseconds
	var d2msecs = new Date(placement).getTime(); // get milliseconds
	var avgTime = (d1msecs + d2msecs) / 2;
	return avgTime;
}



export const millisecondToMinutes = (value: number) => value / 1000 / 60;

interface Named {
	firstName: string;
	lastName: string;
}

interface NamedWithEmail extends Named{
	email: string;	
}

export const getUserName = (item: Named) =>  item ? `${item.lastName || ''} ${item.firstName || ''}` : '–';

export const getUserNameWithEmail = (item?: NamedWithEmail) => item ? `${item.lastName || ''} ${item.firstName || ''}` : '';

export const timeSpanTo12 = (timeSpan: string): string  => {
	if(!timeSpan) return '';

	const parts = timeSpan.split(':'),
		hours = +parts[0],
		minutes = +parts[1];

	return moment().hours(hours).minutes(minutes).format('hh:mm a');

};

export const printPage = (e) => {
	window.print();
};

export const trimText = (text, length) => text && text.length > length ? text.substr(0, length - 3).trim() + '...' : text;

export const daysSince = (date: number, lastDayWorked?: number) => {
	if (!date) {
		return 0;
	}

	let now = lastDayWorked ? new Date(lastDayWorked) : new Date();
	let today = +new Date(now.getFullYear(), now.getMonth(), now.getDate());

	let start = new Date(date);
	let startDate = +new Date(start.getFullYear(), start.getMonth(), start.getDate());

	return startDate > today
		? 0
		: Math.ceil((today - startDate)/(24*60*60*1000));
};

export const getExportUrl = (url, params, filters) =>  {
	let modified = false;
	
	for (let i=0; i<params.length; i++) {
		let param = filters[params[i]];
		
		if (param && +param !== -1) {
			url += modified ? '&' : '?';
			modified = true;
			
			if (params[i] === 'from' || params[i] === 'to') param = moment(param).format().split('+')[0];

			url += `${params[i]}=${param}`;
		}
	}

	return url;
};

export const getCalendarContainer = (selector) => {
	const container = document.querySelectorAll(selector);
	
	return (triggerNode: Element) => container ? container[0] : document.body;
};

export const phoneRegexp = /(\(([0-9]{3})\)\s([0-9]{3})[-]([0-9]{4})|\+([0-9]{11}))/;
export const emailRegexp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const timepickerProps = {
	use12Hours: true,
	format: "h:mm a"
};

export const googleApiKey = 'AIzaSyBMHlFtWHG6NCwl51QGX95dUbVJRQ55_rU';

export const isMobile = () => {
	return typeof window !== 'undefined' && window.innerWidth <= 768;
};

export const addComma = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const addCommaStr = (number: string) => number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');