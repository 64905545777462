import {Action, ActionCreatorsMapObject} from "redux";

import {getReducer, ItemsState} from "@app/store/ItemList";
import {getActionCreators, KnownPageAction} from "@common/react/store/ItemList";

export interface InjuryItemsState<T> {
	[id: number]: ItemsState<T>
}

interface InjuryItemsAction<T> {
	type: string;
	id: number;
	action: KnownPageAction<T>;
}

export function getInjuriesItemsReducer<T>(store: string, ACTION: string) {
	return (state: InjuryItemsState<T>, incomingAction: Action) => {
		if ((incomingAction as any).type != ACTION) {
			return state || {};
		} else {
			const action: InjuryItemsAction<T> = incomingAction as any;

			return {
				...state,
				[action.id]: getReducer(store)(state[action.id] || {isLoading: false, params: {}, items: []}, action.action)
			}
		}
	}
}

const actionCreators = getActionCreators();

export function getSelectedInjuryItemsActionCreators(store: string, ACTION: string) {
	return (id: number): ActionCreatorsMapObject => {
		return Object.keys(actionCreators).reduce((result, key) => {
			result[key] = (...params) => (dispatch, getState) => {
				const sourceActionFunc = actionCreators[key](...params);

				const newDispatch = action => dispatch({
					type: ACTION,
					id: id,
					action: action
				});

				return sourceActionFunc(
					newDispatch,
					() => {
						const state = getState();
						return {
							...state,
							[store]: state[store][id] || {}
						}
					}
				);
			};
			return result;
		}, {});
	}
}

export function getInjuryItemsActionCreators(store: string, ACTION: string) {
	return (): ActionCreatorsMapObject => {
		return Object.keys(actionCreators).reduce((result, key) => {
			result[key] = (id, ...params) => (dispatch, getState) => {
				const sourceActionFunc = actionCreators[key](...params);

				const newDispatch = action => dispatch({
					type: ACTION,
					id: id,
					action: action
				});

				return sourceActionFunc(
					newDispatch,
					() => {
						const state = getState();
						return {
							...state,
							[store]: state[store][id] || {}
						}
					}
				);
			};
			return result;
		}, {});
	}
}