import * as React from 'react';
import { NavLink} from 'react-router-dom';

import {MenuItem} from '@app/objects/MenuItem';

interface MenuItemProps {
	withChildren: boolean;
	item: MenuItem;
	basePath?: string;
	pathKey?: string;
}

interface MenuItemState {
	open: boolean
}

interface MenuProps {
	className?: string;
	items: MenuItem[];
	withChildren?: boolean;
	basePath?: string;
	pathKey?: string;
}


class Item extends React.Component<MenuItemProps, MenuItemState> {
	constructor(props: MenuItemProps) {
		super(props);

		this.state = {
			open: false
		};

		this.toggleMenu = this.toggleMenu.bind(this);
	}
	
	render() {
		const { item, withChildren, basePath, pathKey } = this.props;

		const open = this.state.open;
		
		const condition = withChildren && item.children && item.children.list && item.children.list.length > 0;
		
		const path = item[pathKey || 'path'];
		
		return <li className="menu-component__item">
			{path 
				? <NavLink exact to={basePath + path} className="menu-component__item-link" activeClassName="menu-component__item-link_active" onClick={this.toggleMenu}>{item.name}</NavLink>
				: <span className="menu-component__item-link">{item.name}</span>
			}
			{condition &&
				<React.Fragment>
					<i className={`icons menu-component__item-toggle ${open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`} onClick={this.toggleMenu}></i>
					<div className={`menu-component__item-children-container ${open ? 'isOpen' : ''}`}>
						<ul className="menu-component__children">{item.children && item.children.list.map((child, index) => <Item item={child} key={index} withChildren={withChildren} basePath={child.basePath || basePath || ''} pathKey={pathKey}/>)}</ul>
					</div>
				</React.Fragment>	
			}
		</li>
	}
	
	toggleMenu() {
		this.setState({open: !this.state.open})
	}
}


const Menu: React.SFC<MenuProps> = ({ className = '', items, withChildren = false, basePath, pathKey }) => {
	return <ul className={'menu-component ' + className}>
		{items.map((item, index) => <Item key={index} item={item} withChildren={item.children? true: withChildren } basePath={item.basePath || basePath || ''} pathKey={pathKey} />)}
	</ul>;
};
	
export { Menu };