import { addTask } from 'domain-task';
import {Action, ActionCreatorsMapObject, Reducer} from 'redux';

import { request } from '@common/react/components/Api';
import {BaseApplicationState, BaseAppThunkAction} from '@common/react/store/index';
import {BaseUser} from '@common/react/objects/BaseUser';
import {equal} from '@common/typescript/Utils';
import {BaseParams} from '@common/typescript/objects/BaseParams';

export interface ItemsState<T> {
	isLoading: boolean;
	items: T[];
	pagination: {
		total: number;
		current: number;
		offset: number;
		pageSize?: number;
	};
	type: string;
	params: BaseParams;
}

export enum TypeKeys {
	REQUESTITEMS = 'REQUESTITEMS',
	RECEIVEITEMS = 'RECEIVEITEMS',
	REQUESTMOREITEMS = 'REQUESTMOREITEMS',
	RECEIVEMOREITEMS = 'RECEIVEMOREITEMS',
	UPDATEITEM   = 'UPDATEITEM',
	UPDATEBATCH = 'UPDATEBATCH',
	ADDITEM = 'ADDITEM',
	DELETEITEM = 'DELETEITEM',
	INITSTORAGE = 'INITSTORAGE'
}

interface InitStorageAction<T> {
	type: TypeKeys.INITSTORAGE;
	storageName: string | null;
	items: T[] | null | undefined;
	total?: number | null;
	params: any;
	objectType: string;
}

interface RequestItemsAction {
	type: TypeKeys.REQUESTITEMS;
	storageName: string | null;
	params: any;
	objectType: string;
}

interface ReceiveItemsAction<T> {
	type: TypeKeys.RECEIVEITEMS;
	storageName: string | null;
	items: T[];
	total: number;
	offset: number;
	objectType: string;
}

interface UpdateItemAction<T> {
	type: TypeKeys.UPDATEITEM;
	storageName: string | null;
	paramName: string;
	item: T;
}

export enum ClipBy {
	Start,
	End,
	None
}

interface UpdateBatchAction<T> {
	type: TypeKeys.UPDATEBATCH;
	storageName: string | null;
	paramName: string;
	items: T[];
	sortBy: string | false;
	clip: ClipBy;
	insertNew: boolean;
}

interface RequestMoreItemsAction {
	type: TypeKeys.REQUESTMOREITEMS;
	storageName: string | null;
	params: any;
}

interface ReceiveMoreItemsAction<T> {
	type: TypeKeys.RECEIVEMOREITEMS;
	storageName: string | null;
	items: T[];
	offset: number;
	total: number;
}

interface AddItemAction<T> {
	type: TypeKeys.ADDITEM;
	storageName: string | null;
	item: T;
}

interface DeleteItemAction<T> {
	type: TypeKeys.DELETEITEM;
	storageName: string | null;
	id: number;
}

export interface Rows<T> {
	list: T[];
	count: number;
	offset: number;
}

export type KnownPageAction<T> = InitStorageAction<T>
	| RequestItemsAction
	| ReceiveItemsAction<T>
	| UpdateItemAction<T>
	| RequestMoreItemsAction
	| ReceiveMoreItemsAction<T>
	| AddItemAction<T>
	| DeleteItemAction<T>
	| UpdateBatchAction<T>;

// tslint:disable-next-line:max-line-length
function loadPage<T, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>(dispatch: any, getState: any, store: string, type: string, path: string, params: any) {
	const fetchTask = request<Rows<T>, TUser, TApplicationState>(path, params, getState()).then((data) => {
		dispatch({ 
			type: TypeKeys.RECEIVEITEMS, 
			storageName: store, 
			items: data.list, 
			total: data.count, 
			objectType: type, 
			params: params, 
			offset: data.offset 
		});
	});

	addTask(fetchTask);

	dispatch({ type: TypeKeys.REQUESTITEMS, storageName: store, params: params, objectType: type });

	return fetchTask;
}

export function getActionCreators<T, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>(): ActionCreatorsMapObject {
	return {
		initStorage: (
			store: string, 
			type: string, 
			items?: T[], 
			params?: any
		): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			dispatch({
				items: items,
				params: params,
				type: TypeKeys.INITSTORAGE,
				storageName: store,
				objectType: type,
			});
		},
		reqPages: (
			store: string, 
			path: string, 
			type: string, 
			params: BaseParams
		): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			const storeState = (getState() as any)[store];

			if (!equal(storeState.params, params) || storeState.type !== type) {
				return loadPage<T, TUser, TApplicationState>(dispatch, getState, store, type, path, params);
			}

			return Promise.resolve(null);
		},
		removeItem: (
			store: string, 
			path: string, 
			type: string, 
			item: T, 
			newParams: BaseParams | null = null
		): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			(item as any).deleted = true;

			const params = (getState() as TApplicationState)[store].params;

			return request<any, TUser, TApplicationState>(type, item, getState()).then( data =>
				loadPage<T, TUser, TApplicationState>(dispatch, getState, store, type, path,  newParams ? {...params, ...newParams} : params)
			);
		},
		refreshPages: (
			store: string, 
			path: string, 
			params?: BaseParams
		): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			const storeState = (getState() as any)[store];

			return loadPage<T, TUser, TApplicationState>(dispatch, getState, store, storeState.type, path, params || storeState.params);
		},
		updateItem: (
			store: string, 
			item: T, 
			paramName: string = 'id'
		): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			dispatch({type: TypeKeys.UPDATEITEM, storageName: store, item: item, paramName: paramName});
		},
		/**
		 * UpdateBatch - action to update an array of entities
		 * @param store		{string}			- store to update at
		 * @param items		{items}				- items to be updated
		 * @param paramName	{string}			- field to compare items by
		 * @param sortBy	{string | false}	- whether to sort or not and what field to sort by
		 * @param clip		{ClipBy}			- whether to clip resulting items array to its original size and where to align to
		 * @param insertNew	{boolean}			- whether to insert new items or only to update existing ones
		 */
		updateBatch: (
			store: string, 
			items: T[], 
			paramName: string = 'id', 
			sortBy: string | false = false, 
			clip: ClipBy, 
			insertNew: boolean = false
		): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			dispatch({
				items,
				paramName,
				sortBy,
				clip,
				insertNew,
				type: TypeKeys.UPDATEBATCH,
				storageName: store,
			});
		},
		loadMoreItems: (
			store: string, 
			path: string, 
			count: number
		): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			const storeState = (getState() as any)[store];

			const params = {
				...storeState.params,
				offset: (storeState.params.offset || 0) + storeState.params.count
			};

			const fetchTask = request<Rows<T>, TUser, TApplicationState>(path, params, getState()).then((data) => {
				dispatch({type: TypeKeys.RECEIVEMOREITEMS, storageName: store, items: data.list, offset: data.offset, total: data.count});
			});

			addTask(fetchTask);

			dispatch({type: TypeKeys.REQUESTMOREITEMS, storageName: store, params: params});
		},
		addItem: (store: string, item: T): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch) => {
			dispatch({type: TypeKeys.ADDITEM, storageName: store, item: item});
		},
		deleteItem: (store: string, id: number): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			dispatch({type: TypeKeys.DELETEITEM, storageName: store, id: id});
		}
	};
}

export function getReducer<T>(storageName: string):Reducer<ItemsState<T>> {
	return (state: ItemsState<T>, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction<T>;
		if (!action.storageName || action.storageName === storageName) {
			switch (action.type) {
				case TypeKeys.INITSTORAGE:
					return {
						isLoading: false,
						items: action.items || [],
						params: action.params || {},
						pagination: {total: action.total || (action.items && action.items.length) || 0, current: 0, offset: 0, pageSize: action.params.count || 10},
						type: action.objectType
					};
				case TypeKeys.REQUESTITEMS:
					return {...state, isLoading: true, params: action.params, type: action.objectType};
				case TypeKeys.RECEIVEITEMS:
					return {
						isLoading: false,
						items: action.items,
						params: state.params,
						pagination: {total: action.total, current: state.params.page, offset: action.offset, pageSize: state.params.count || 10},
						type: action.objectType
					};
				case TypeKeys.UPDATEITEM:
					return {
						...state,
						items: state.items.map((item) => {
							return item[action.paramName] === action.item[action.paramName] ? {...(item as any), ...(action.item as any)} : item;
						})
					};
				case TypeKeys.UPDATEBATCH:
					
					const size = state.items.length;
					let items = state.items
						.map((item) => {
							const uid = action.items.findIndex(elem =>
								elem[action.paramName] === item[action.paramName]);

							if (uid === -1) return item;

							return {...(item as any), ...(action.items[uid] as any)};
						});
					
					if (action.insertNew) {
						items = items.concat(action.items.filter(itm =>
							!items.some(storedItem =>
								storedItem[action.paramName] === itm[action.paramName])));
					}
					
					if (action.sortBy !== false) {
						items = items.sort((a, b) =>
							a[action.sortBy as string] - b[action.sortBy as string]);
					}
					
					switch (action.clip) {
						case ClipBy.Start:
							items = items.slice(0, size);
							break;

						case ClipBy.End:
							const shift = items.length - size;
							items = items.slice(shift, shift + size);
							break;

						case ClipBy.None:
						default:
							break;
					}

					return {
						...state,
						items,
					};
				case TypeKeys.REQUESTMOREITEMS:
					return {...state, isLoading: true, params: action.params};
				case TypeKeys.RECEIVEMOREITEMS:
					return {
						...state,
						items: state.items.concat(action.items),
						isLoading: false,
						pagination: {total: action.total, current: state.params.page, offset: action.offset, pageSize: state.params.count || 10},
					};
				case TypeKeys.ADDITEM:
					return state.items
						? {
							...state,
							items: [action.item].concat(state.items)
						}
						: state;
				case TypeKeys.DELETEITEM:
					return{
						...state,
						pagination: {
							...state.pagination,
							total: state.pagination.total - 1
						},
						items: state.items.filter(item => item['id'] !== action.id)
					};
				default:
					const exhaustiveCheck: never = action;
			}

			return state || {
				isLoading: false, params: {}, items: []
			};
		}

		return state;
	};
}