import * as React from 'react';

import { request } from '@common/react/components/Api';

import Button from '@common/react/components/Forms/Button';
import {User, UserRole} from "@app/objects/User";
import {getUserNameWithEmail} from "@app/components/Utils/Utils";
import Autocomplete, {Option} from "@common/react/components/Forms/Autocomplete/Autocomplete";

interface TransmutationProps {
	transmuted: boolean;
	title?: string;
}

interface TransmutationState {
	isLoading: boolean;
	transmuted: boolean;
	userId: number;
}

export default class HeaderTransmutation extends React.Component<TransmutationProps, TransmutationState> {
	public static defaultProps: Partial<TransmutationProps> = {
		title: "Login as"
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			transmuted: false,
			userId: 0
		};

		this.handlerClick = this.handlerClick.bind(this);
	}

	handlerClick() {
		this.setState({ isLoading: true });
		
		request('transmutation', {
			id: this.props.transmuted ? 0 : this.state.userId ? this.state.userId : 0,
			transmutation: !this.state.transmuted
		}).then(() => {
			window.location.reload();
		});
	}

	render() {
		if (this.props.transmuted) {
			return <a onClick={this.handlerClick}>Back</a>
		}
		
		return (
			<div className="header-autocomplete">
				<div className="pull-left">
					<Autocomplete
						type="userList"
						renderOption={(item: User) => {
							const name = getUserNameWithEmail(item);
							return <Option key={item.id + ''} value={item.id + ''} title={name}>{name}</Option>
						}}
						onSelect={(value, option) => {
							this.setState({userId: +value});
						}}
						onChange={(value = '') => {
							value === '' && this.setState({userId: 0});
						}}
						value={''}
						params={{
							roles: [UserRole.Internal, UserRole.External]
						}}
						loadOnFocus={true}
						placeholder="Login as"
					/>
				</div>
				<Button onClick={this.handlerClick} isLoading={this.state.isLoading} className="btn btn-sm btn-default" title={this.props.title} disabled={!this.state.userId}><i className="fa fa-child" aria-hidden="true"></i></Button>
			</div>
		)
	}
}