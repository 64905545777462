
import Schedule from "@app/objects/Schedule";
import {getInjuriesItemsReducer, getSelectedInjuryItemsActionCreators} from "@app/store/Injury";

const INJURY_ITEMS_ACTION = 'INJURY_SCHEDULE_ACTION';

//instead of dispatching action dispatch { type: INJURY_FILE_ACTION, injuryId: injuryId, action: action }
export const getSelectedInjuryJobsActionCreators = getSelectedInjuryItemsActionCreators('injuryJobs', INJURY_ITEMS_ACTION);


export const injuryJobsReducer = getInjuriesItemsReducer<Schedule>('injuryJobs', INJURY_ITEMS_ACTION);