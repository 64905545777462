import {Loading} from "@app/components/UI/Loading";

import * as React from 'react';

function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export const loadableDelay = promise => {
	if (typeof window === 'undefined') return promise;

	let promiseErr;

	promise = promise.catch(err => {
		promiseErr = err;
	});

	return Promise.all([promise, delay(200)]).then(val => promiseErr ? Promise.reject(promiseErr) : val[0])
};

export const params = {
	fallback: <Loading/>
};
