import 'raf/polyfill';

import 'core-js/features/array/from';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/weak-map';
import 'core-js/features/promise';
import 'core-js/features/string/starts-with';
import 'core-js/features/object/values';
import 'core-js/features/object/assign';
import 'core-js/features/object/is';

import {bootClient, renderApp} from '@common/react/loadable/boot-client';
import { routes } from './routes';
import {ApplicationState, reducers} from "@app/store";
import {updateReducers} from "@common/react/configureStore";
import {User} from "@app/objects/User";

bootClient<User, ApplicationState>(routes, reducers);

//Allow Hot Module Replacement
if (module.hot) {
	module.hot.accept('./routes', () => {
		renderApp(require('./routes').routes);
	});
}

// Enable Webpack hot module replacement for reducers
if (module.hot) {
	module.hot.accept('./store', () => {
		const nextRootReducer = require('./store');
		updateReducers(nextRootReducer.reducers)
	});
}