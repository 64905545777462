import {ReducersMapObject} from 'redux';

import * as Login from '@common/react/store/Login';
import {BaseUser} from '@common/react/objects/BaseUser';
import {CommentsState, getReducer as getCommentsReducer} from '@common/react/store/Comments';

// The top-level state object
export interface BaseApplicationState<TUser extends BaseUser> {
	login: Login.LoginState<TUser>;
	comments: CommentsState<TUser>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const baseReducers: ReducersMapObject = {
	login: Login.getReducer(),
	comments: getCommentsReducer()
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface BaseAppThunkAction<TAction, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>  {
	(dispatch: (action: TAction) => void, getState: () => TApplicationState): void;
}
