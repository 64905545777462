import { addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';

import { request } from '@common/react/components/Api';
import {BaseUser} from '@common/react/objects/BaseUser';
import {BaseParams} from '@common/react/objects/BaseParams';

import {BaseApplicationState, BaseAppThunkAction} from './';

export interface ItemState<T> {
	isLoading: boolean;
	id: number;
	itemPathOrId: string | number | null;
	item: T;
}

export enum TypeKeys {
	REQUESTITEM = 'REQUESTITEM',
	RECEIVEITEM = 'RECEIVEITEM'
}

interface RequestItemAction {
	type: TypeKeys.REQUESTITEM;
	storageName: string | null;
	itemPathOrId: string | number;
}

interface ReceiveItemAction {
	type: TypeKeys.RECEIVEITEM;
	storageName: string | null;
	item: any;
}

type KnownPageAction = RequestItemAction | ReceiveItemAction;

export interface IActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	loadItem: (
		type: string, 
		path: string, 
		itemPathOrId: string | number, 
		defaultItem: any, 
		additionaParams?: BaseParams
	) => BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
	updateItem: (type: string, data: any) => BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
}

export function getActionCreators<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>() {
	return {
		loadItem: (
			type: string, 
			path: string, 
			itemPathOrId: string | number, 
			defaultItem: any, 
			additionaParams: BaseParams = {}
		): BaseAppThunkAction<KnownPageAction, TUser, TApplicationState> => (dispatch, getState) => {
			const storeState = (getState() as any)[type];
			const id = Number(itemPathOrId);

			if ((!isNaN(id) && storeState.id !== id)
				|| (isNaN(id) && storeState.itemPath !== itemPathOrId)
				|| (storeState.item && storeState.item._type !== type)) {
				if (id > 0 || isNaN(id)) {
					const params = !isNaN(id)
						? {id: id, ...additionaParams}
						: {path: itemPathOrId, ...additionaParams};

					const fetchTask = request(
						path,
						params,
						getState()
					).then(data => dispatch({type: TypeKeys.RECEIVEITEM, storageName: type, item: data}));

					addTask(fetchTask);
					dispatch({type: TypeKeys.REQUESTITEM, storageName: type, itemPathOrId: itemPathOrId});
				} else {
					dispatch({type: TypeKeys.RECEIVEITEM, storageName: type, item: defaultItem || {}});
				}
			}
		},
		updateItem: (type: string, data: any): BaseAppThunkAction<KnownPageAction, TUser, TApplicationState> => (dispatch, getState) => {
			const storeState = (getState() as any)[type];

			if (storeState) {
				const item = storeState.item;

				dispatch({ type: TypeKeys.RECEIVEITEM, storageName: type, item: { ...item, ...data } });
			}
		}
	};
}

export function getReducer<T>(storageName: string):Reducer<ItemState<T>> {
	return (state: ItemState<T>, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction;
		if (!action.storageName || action.storageName === storageName) {
			switch (action.type) {
				case TypeKeys.REQUESTITEM:
					return {
						isLoading: true,
						item: state.item,
						id: Number(action.itemPathOrId),
						itemPathOrId: action.itemPathOrId
					};
				case TypeKeys.RECEIVEITEM:
					return {isLoading: false, item: action.item, id: state.id, itemPathOrId: null};
				default:
					const exhaustiveCheck: never = action;
			}

			return state || {isLoading: false, id: null, item: null};
		} 
		
		return state;
	};
}