import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Logout from '@common/react/components/UI/Logout/Logout';
import "bootstrap/dist/css/bootstrap.css";

import "trumbowyg/dist/ui/trumbowyg.min.css";

import 'antd/dist/antd.css';

import "react-big-calendar/lib/css/react-big-calendar.css";

import '@app/scss/style.scss';

import './setLocale';
import { connect } from 'react-redux';
import '@common/typescript/polyfills/array';
import Header from './UI/Header/Header';
import Footer from './UI/Footer/Footer';
import LeftPanel from './UI/LeftPanel/LeftPanel';
import { Fragment } from 'react';
import { RouteProps } from "react-router";
import SignalR from './SignalR';
import IdleTimer from 'react-idle-timer';
import Swal from 'sweetalert2';
//import { LoginActionCreators as BaseLoginActionCreators, LoginState as BaseLoginState, getActionCreators } from '@common/react/store/Login';
//import { BaseUser } from '@common/react/objects/BaseUser';
//import { BaseApplicationState } from '@common/react/store';
//interface OwnProps {
//    render?: (logout: (event: React.MouseEvent) => void) => JSX.Element;
//}

//type ActionCreators = BaseLoginActionCreators<BaseUser, BaseApplicationState<BaseUser>>;

//type LoginState = BaseLoginState<BaseUser>;

//type LogoutProps =
//    LoginState
//    & ActionCreators
//    & OwnProps;

type PageProps = RouteComponentProps<any> & RouteProps;

interface PageState {
    collapsed: boolean,
    isTimedOut: boolean,
    timeout: number,
    showModal: boolean
}


const outerPages = ['/', '/login', '/recover', '/confirm'];

class Layout extends React.Component<PageProps, PageState> {
    idleTimer: any;
    _document: any;
    constructor(props: PageProps) {
        super(props);

        this.state = {
            collapsed: false,
            isTimedOut: false,
            timeout: 1000 * 4000 * 1,
            showModal: false
        };
        //this.idleTimer = null;
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onIdle = this.onIdle.bind(this)
        this.onAction = this.onAction.bind(this)
        this.onActive = this.onActive.bind(this)
        //this.logout = this.logout.bind(this);
        this._document = null;
    }

    //shouldComponentUpdate(nextProps: LogoutProps, nextState: Readonly<{}>, nextContext: any): boolean {
    //    return nextProps.user !== this.props.user;
    //}

    onIdle(e) {
        console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        
        if (isTimedOut && this.props.location.pathname !="/") {
            
            //this.props.history.push('/')
            Swal.fire({
                title: 'You Will Get Timed Out. You want to stay?',
                showDenyButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: `Stay`,
                denyButtonText: `Logout`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.idleTimer.reset();
                    this.setState({ isTimedOut: false })
                } else if (result.isDenied) {
                    //this.logout(e);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            })

        } else {
            this.setState({ showModal: true })
            this.idleTimer.reset();
            this.setState({ isTimedOut: true })
        }
    }

    onAction(e) {
        //console.log('user did something', e)
        this.setState({ isTimedOut: false })
    }

    onActive(e) {
        //console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    logout(event: React.MouseEvent) {
        //event.preventDefault();
        //this.props.logoff();
    }

    toggleMenu() {
        this.setState({ collapsed: !this.state.collapsed })
    }

    componentDidMount() {
        this.setState({ collapsed: this.isMobile() });
    }

    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);

        if (this.isMobile() && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ collapsed: true });
        }
    }

    isMobile(): boolean {
        return typeof window !== 'undefined' && window.innerWidth <= 768;
    }

    public render() {
        const isInnerPage = outerPages.indexOf(this.props.location.pathname) == -1;
        //return <div className="app-container">
        //    <div className="content">
        //        <SignalR />
        //        {isInnerPage &&
        //            <Fragment>
        //                <Header menuItems={[]} />
        //                <LeftPanel collapsed={this.state.collapsed} />
        //                <button className="left-panel-collapse-button hide-print" onClick={this.toggleMenu}>
        //                    {this.state.collapsed
        //                        ? <i className="fa fa-indent" aria-hidden="true"></i>
        //                        : <i className="fa fa-outdent" aria-hidden="true"></i>
        //                    }
        //                </button>
        //            </Fragment>
        //        }
        //        <div className="content-inner">
        //            {this.props.children}
        //        </div>
        //        <Footer />
        //    </div>
        //        </div>;

        return(
            <>
                <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                onActive={this.onActive}
                onIdle={this.onIdle}
               onAction={this.onAction}
               debounce={250}
               timeout={this.state.timeout} />

        <div className="app-container">
            <div className="content">
                <SignalR />
                {isInnerPage &&
                    <Fragment>
                        <Header menuItems={[]} />
                        <LeftPanel collapsed={this.state.collapsed} />
                        <button className="left-panel-collapse-button hide-print" onClick={this.toggleMenu}>
                            {this.state.collapsed
                                ? <i className="fa fa-indent" aria-hidden="true"></i>
                                : <i className="fa fa-outdent" aria-hidden="true"></i>
                            }
                        </button>
                    </Fragment>
                }
                <div className="content-inner">
                    {this.props.children}
                </div>
                <Footer />
            </div>
                </div>
            </>
            )
    }
}
//export default withRouter(connect < LoginState, {} ,any>(
//    (state: BaseApplicationState<BaseUser>) => state.login,
   
//)(Layout));

export default withRouter(Layout) //as typeof Layout;