import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import Layout from './components/Layout';

import loadable from '@loadable/component'
import {loadableDelay, params} from "@app/loadableSettings";
import PrivateRoute from '@common/react/components/Pages/PrivateRoute';


/*--------------Admin---------------*/
	// import Users from './components/Pages/Admin/Users/Users';
	// import UserEditor from './components/Pages/Admin/Users/UserEditor';
	
	// import Languages from './components/Pages/Admin/Languages/Languages';
	// import CompanyTypes from './components/Pages/Admin/CompanyTypes/CompanyTypes';
	// import FollowUpTypes from './components/Pages/Admin/FollowUpTypes/FollowUpTypes';
	// import InjuryTypes from "@app/components/Pages/Admin/InjuryTypes/InjuryTypes";
	// import OutcomeTypes from "@app/components/Pages/Admin/OutcomeTypes/OutcomeTypes";
	// import Templates from '@app/components/Pages/Admin/Templates/Templates';
	// import TemplateEditor from '@app/components/Pages/Admin/Templates/TemplateEditor';
	// import InjuryPendingReasons from "@app/components/Pages/Admin/InjuryPendingReasons/InjuryPendingReasons";
	// import Occupations from "@app/components/Pages/Admin/Occupations/Occupations";
	// import Questions from "@app/components/Pages/Admin/Questions/Questions";
	// import InjuryEmails from '@app/components/Pages/Admin/InjuryEmails/InjuryEmails';

const InjuryEmails = loadable(() => loadableDelay(import( /* webpackChunkName: "InjuryEmailsPage" */ './components/Pages/Admin/InjuryEmails/InjuryEmails')), params);
const MailBox = loadable(() => loadableDelay(import( /* webpackChunkName: "MailBoxPage" */ './components/Pages/MailBox/MailBox')), params);
const Kanban = loadable(() => loadableDelay(import( /* webpackChunkName: "KanbanPage" */ './components/Pages/Projects/Kanban')), params);
const Calender = loadable(() => loadableDelay(import( /* webpackChunkName: "MailBoxPage" */ './components/Pages/MailBox/Calender')), params);
const ProjectCalender = loadable(() => loadableDelay(import( /* webpackChunkName: "ProjectCalender" */ './components/Pages/Projects/ProjectCalender')), params);
	
	const Users = loadable(
		() => loadableDelay(import(/* webpackChunkName: "UserEditorPage" */ './components/Pages/Admin/Users/Users')), params
	);

	const UserEditor = loadable(
		() => loadableDelay(import(/* webpackChunkName: "UsersPage" */ './components/Pages/Admin/Users/UserEditor')),
		params
	);
	
	const Languages = loadable(
	    () =>  loadableDelay(import(/* webpackChunkName: "LanguagesPage" */ './components/Pages/Admin/Languages/Languages')),
		params
	);
	
	const CompanyTypes = loadable(
		 () => loadableDelay(import(/* webpackChunkName: "CompanyTypesPage" */ './components/Pages/Admin/CompanyTypes/CompanyTypes')),
		params
	);
	
	const FollowUpTypes = loadable(
		() => loadableDelay(import(/* webpackChunkName: "FollowUpTypesPage" */ './components/Pages/Admin/FollowUpTypes/FollowUpTypes')),
		params
);

const Groups = loadable(
    () => loadableDelay(import(/* webpackChunkName: "Groups" */ './components/Pages/Admin/Groups/Groups')),
    params
);

const EmailAccounts = loadable(
    () => loadableDelay(import(/* webpackChunkName: "EmailAccounts" */ './components/Pages/Admin/EmailAccounts/EmailAccounts')),
    params
);

const SpamFilters = loadable(
    () => loadableDelay(import(/* webpackChunkName: "SpamFilters" */ './components/Pages/Admin/SpamFilters/SpamFilters')),
    params
);
	
	const InjuryTypes = loadable(
		() => loadableDelay(import(/* webpackChunkName: "InjuryTypesPage" */ './components/Pages/Admin/InjuryTypes/InjuryTypes')),
		params
	);

const ProjectTypes = loadable(
    () => loadableDelay(import(/* webpackChunkName: "ProjectTypesPage" */ './components/Pages/Admin/Projects/ProjectTypes')),
    params
);

const Projects = loadable(
    () => loadableDelay(import(/* webpackChunkName: "ProjectsPage" */ './components/Pages/Admin/Projects/Projects')),
    params
);


const ProjectTasks = loadable(
    () => loadableDelay(import(/* webpackChunkName: "ProjectsPage" */ './components/Pages/Admin/Projects/ProjectTasks')),
    params
);


const ProjectTaskEditor = loadable(
    () => loadableDelay(import(/* webpackChunkName: "ProjectsPage" */ './components/Pages/Admin/Projects/TaskEditor')),
    params
);

const TaskLists = loadable(
    () => loadableDelay(import(/* webpackChunkName: "ProjectsPage" */ './components/Pages/Admin/Projects/TaskLists')),
    params
);

const ProjectUserKanban = loadable(
    () => loadableDelay(import(/* webpackChunkName: "ProjectsPage" */ './components/Pages/Projects/ProjectUserKanban')),
    params
);
const ProjectSessions = loadable(
    () => loadableDelay(import(/* webpackChunkName: "ProjectSessionsPage" */ './components/Pages/Admin/Projects/ProjectSessions')),
    params
);


const SessionImages = loadable(
    () => loadableDelay(import(/* webpackChunkName: "SessionImagesPage" */ './components/Pages/Admin/Projects/SessionImages')),
    params
);


const ProjectAudits = loadable(
    () => loadableDelay(import(/* webpackChunkName: "ProjectAuditsPage" */ './components/Pages/Admin/Projects/ProjectAudits')),
    params
);

	const OutcomeTypes = loadable(
		() => loadableDelay(import(/* webpackChunkName: "OutcomeTypesPage" */ './components/Pages/Admin/OutcomeTypes/OutcomeTypes')),
		params
	);
	
	const Templates = loadable(
		() => loadableDelay(import(/* webpackChunkName: "TemplatesPage" */ './components/Pages/Admin/Templates/Templates')),
		params
	);

	const TemplateEditor = loadable(
		() => loadableDelay(import(/* webpackChunkName: "TemplateEditorPage" */ './components/Pages/Admin/Templates/TemplateEditor')),
		params
	);

	const Holidays = loadable(
		() => loadableDelay(import(/* webpackChunkName: "HolidaysPage" */ './components/Pages/Admin/Holidays/Holidays')),
		params
	);
	const HolidayEditor = loadable(
		() => loadableDelay(import(/* webpackChunkName: "HolidayEditorPage" */ './components/Pages/Admin/Holidays/HolidayEditor')),
		params
	);
	

	const InjuryPendingReasons = loadable(
		() => loadableDelay(import(/* webpackChunkName: "InjuryPendingReasonsPage" */ './components/Pages/Admin/InjuryPendingReasons/InjuryPendingReasons')),
		params
	);

	const Occupations = loadable(
		() => loadableDelay(import(/* webpackChunkName: "OccupationsPage" */ './components/Pages/Admin/Occupations/Occupations')),
		params
	);
	
	const Questions = loadable(
		() => loadableDelay(import(/* webpackChunkName: "QuestionsPage" */ './components/Pages/Admin/Questions/Questions')),
		params
	);

	const Settings = loadable(
		() => loadableDelay(import(/* webpackChunkName: "SettingsPage" */ './components/Pages/Admin/GlobalSettings/GlobalSettings')),
		params
	);
	
/*------------Admin end-------------*/

// import Login from './components/Pages/Login/Login';

const Login = loadable(
	() => loadableDelay(import(/* webpackChunkName: "LoginPage" */ './components/Pages/Login/Login')),
	params
);

// import Recover from './components/Pages/Recover/Recover';

const Recover = loadable(
	() => loadableDelay(import(/* webpackChunkName: "RecoverPage" */ './components/Pages/Recover/Recover')),
	params
);

// import Confirm from './components/Pages/Confirm/Confirm';

const Confirm = loadable(
	() => loadableDelay(import(/* webpackChunkName: "ConfirmPage" */ './components/Pages/Confirm/Confirm')),
	params
);

// import Profile from './components/Pages/Profile';

const Profile = loadable(
	() => loadableDelay(import(/* webpackChunkName: "ProfilePage" */ './components/Pages/Profile')),
	params
);


// import Employees from './components/Pages/Employees/Employees';

const Employees = loadable(
	() => loadableDelay(import(/* webpackChunkName: "EmployeesPage" */ './components/Pages/Employees/Employees')),
	params
);


// import EmployeeEditor from './components/Pages/Employees/EmployeeEditor';

const EmployeeEditor = loadable(
	() => loadableDelay(import(/* webpackChunkName: "EmployeeEditorPage" */ './components/Pages/Employees/EmployeeEditor')),
	params
);


// import ReferralEditor from './components/Pages/Employees/ReferralEditor/ReferralEditor';

const ReferralEditor = loadable(
	() => loadableDelay(import(/* webpackChunkName: "ReferralEditorPage" */ './components/Pages/Employees/ReferralEditor/ReferralEditor')),
	params
);


// import Companies from './components/Pages/Companies/Companies';

const Companies = loadable(
	() => loadableDelay(import(/* webpackChunkName: "CompaniesPage" */ './components/Pages/Companies/Companies')),
	params
);

// import CompanyEditor from './components/Pages/Companies/CompanyEditor';

const CompanyEditor =  loadable(
	() => loadableDelay(import(/* webpackChunkName: "CompanyEditorPage" */ './components/Pages/Companies/CompanyEditor')),
	params
);

// import Reports from './components/Pages/Reports/Reports';

const Reports = loadable(
	() => loadableDelay(import(/* webpackChunkName: "ReportsPage" */ './components/Pages/Reports/Reports')),
	params
);

// import Calls from './components/Pages/Admin/Calls/Calls';

const Calls = loadable(
	() => loadableDelay(import(/* webpackChunkName: "CallsPage" */ './components/Pages/Admin/Calls/Calls')),
	params
);

// import StatusReports from "@app/components/Pages/StatusReports/StatusReports";

const StatusReports = loadable(
	() => loadableDelay(import(/* webpackChunkName: "StatusReportsPage" */ './components/Pages/StatusReports/StatusReports')),
	params
);

// import Dashboard from "@app/components/Pages/Dashboard/Dashboard";

const Dashboard = loadable(
	() => loadableDelay(import(/* webpackChunkName: "DashboardPage" */ './components/Pages/Dashboard/Dashboard')),
	params
);

// import InjuryEmailEditor from "@app/components/Pages/Admin/InjuryEmails/InjuryEmailEditor";

const InjuryEmailEditor = loadable(
	() => loadableDelay(import(/* webpackChunkName: "InjuryEmailEditorPage" */ './components/Pages/Admin/InjuryEmails/InjuryEmailEditor')),
	params
);

const MailBoxEditor = loadable(
    () => loadableDelay(import(/* webpackChunkName: "MailBoxEditorPage" */ './components/Pages/MailBox/MailBoxEmailEditor')),
	params
);

const Chats = loadable(
	() => loadableDelay(import(/* webpackChunkName: "ChatsPage" */ './components/Pages/Chats/ChatsPage')),
	params
);


const EmailSignatureEditor = loadable(
	() => loadableDelay(import(/* webpackChunkName: "EmailSignatureEditorPage" */ './components/Pages/Admin/EmailSignatures/EmailSignatureEditor')),
	params
);

const Alerts = loadable(
	() => loadableDelay(import(/* webpackChunkName: "AlertsPage" */ './components/Pages/Admin/Alerts/Alerts')),
	params
);

const QuartzJobs = loadable(
	() => loadableDelay(import(/* webpackChunkName: "QuartzJobsPage" */ './components/Pages/Admin/QuartzJobs/QuartzJobs')),
	params
);

const Titles = loadable(
	() => loadableDelay(import(/* webpackChunkName: "TitlesPage" */ './components/Pages/Admin/Titles/Titles')),
	params
);

const ReportsActivity = loadable(
	() => loadableDelay(import(/* webpackChunkName: "ReportsActivityPage" */ './components/Pages/ReportsActivity/ReportsActivity')),
	params
);

const MapPage = loadable(
	() => loadableDelay(import(/* webpackChunkName: "MapPage" */ './components/Pages/MapPage/MapPage')),
	params
);

const CompanyContacts = loadable(
	() => loadableDelay(import(/* webpackChunkName: "CompanyContactsPage" */ './components/Pages/Companies/CompanyContacts')),
	params
);

const ScheduleInfos = loadable(
	() => loadableDelay(import(/* webpackChunkName: "ScheduleInfosPage" */ './components/Pages/Admin/ScheduleInfos/ScheduleInfos')),
	params
);

const ShippingVendors = loadable(
	() => loadableDelay(import(/* webpackChunkName: "ShippingVendorsPage" */ './components/Pages/Admin/ShippingVendors/ShippingVendors')),
	params
);

const CorrespondenceTypes = loadable(
	() => loadableDelay(import(/* webpackChunkName: "CorrespondenceTypesPage" */ './components/Pages/Admin/CorrespondenceTypes/CorrespondenceTypes')),
	params
);

const OutcomeTypeCounters = loadable(
	() => loadableDelay(import(/* webpackChunkName: "OutcomeTypeCountersPage" */ './components/Pages/Admin/OutcomeCounters/OutcomeCounters')),
	params
);

const CallEditor = loadable(
	() => loadableDelay(import(/* webpackChunkName: "CallEditorPage" */ './components/Pages/Admin/Calls/CallEditor')),
	params
);
const EmailAccountEditor = loadable(
	() => loadableDelay(import(/* webpackChunkName: "EmailAccountEditor" */ './components/Pages/Admin/EmailAccounts/EmailAccountEditor')),
	params
);

export const routes = <Layout>
	<Switch>
		<Route exact path='/' component={Login} />
		<Route path='/login' component={Login} />
		<PrivateRoute path='/dashboard' component={Dashboard} />
		<PrivateRoute path='/chats' component={Chats} />
		<Route path='/recover' component={Recover} />
		<Route path='/confirm' component={Confirm} />
		<PrivateRoute path='/profile' component={Profile} />
		<PrivateRoute path='/user-list/:page?' component={Users} />
        <PrivateRoute path='/user-editor/:id' component={UserEditor} />
        <PrivateRoute path='/emailaccount-editor/:id' component={EmailAccountEditor} />
		<PrivateRoute path='/employees/:page?' component={Employees} />
		<PrivateRoute path='/employe-editor/:id' component={EmployeeEditor} />
		<PrivateRoute path='/referral-editor/:id' component={ReferralEditor} />
		<PrivateRoute path='/companies/:page?' component={Companies} />
		<PrivateRoute path='/company-editor/:id' component={CompanyEditor} />
		<PrivateRoute path='/language-list/:page?' component={Languages} />
		<PrivateRoute path='/companyTypes/:page?' component={CompanyTypes} />
		<PrivateRoute path='/injuryTypes/:page?' component={InjuryTypes} />
		<PrivateRoute path='/projectTypes/:page?' component={ProjectTypes} />
        <PrivateRoute path='/projects/:page?' component={Projects} />
        <PrivateRoute path='/projectTasks/:page?' component={ProjectTasks} />
        <PrivateRoute path='/tasklists/:page?' component={TaskLists} />
        <PrivateRoute path='/projectuserkanban/:page?' component={ProjectUserKanban} />
        <PrivateRoute path='/projectSessions/:page?' component={ProjectSessions} />
        <PrivateRoute path='/projectAudits/:page?' component={ProjectAudits} />
        <PrivateRoute path='/sessionImages/:page?' component={SessionImages} />
		<PrivateRoute path='/injuryPendingReasons/:page?' component={InjuryPendingReasons} />
		<PrivateRoute path='/occupations/:page?' component={Occupations} />
		<PrivateRoute path='/injury-reports/:page?' component={Reports} />
		<PrivateRoute path='/calls/:page?' component={Calls} />
		<PrivateRoute path='/questions/:page?' component={Questions} />
        <PrivateRoute path='/followUpTypes/:page?' component={FollowUpTypes} />
        <PrivateRoute path='/groups/:page?' component={Groups} />
        <PrivateRoute path='/emailaccounts/:page?' component={EmailAccounts} />
        <PrivateRoute path='/spamfilters/:page?' component={SpamFilters} />
		<PrivateRoute path='/outcomeTypes/:page?' component={OutcomeTypes} />
		<PrivateRoute path='/templates/:page?' component={Templates} />
		<PrivateRoute path='/template-editor/:id' component={TemplateEditor} />

		<PrivateRoute path='/holidays/:page?' component={Holidays} />
		<PrivateRoute path='/holiday-editor/:id' component={HolidayEditor} />

		<PrivateRoute path='/projecttask-editor/:id' component={ProjectTaskEditor} />
		<PrivateRoute path='/status-report-list/:page?' component={StatusReports} />
		<PrivateRoute path='/email-editor/:id' component={InjuryEmailEditor} />
        <PrivateRoute path='/email-list/:page?' component={InjuryEmails} />
        <PrivateRoute path='/mailbox-editor/:id' component={MailBoxEditor} />
        <PrivateRoute path='/mailbox/:page?' component={MailBox} />
        <PrivateRoute path='/kanban/:page?' component={Kanban} />
        <PrivateRoute path='/calendar/:page?' component={Calender} />
        <PrivateRoute path='/projectcalendar/:page?' component={ProjectCalender} />
		<PrivateRoute path='/email-signature-editor' component={EmailSignatureEditor} />
		<PrivateRoute path='/alert-list/:page?' component={Alerts} />
		<PrivateRoute path='/quartz-job-list/:page?' component={QuartzJobs} />
		<PrivateRoute path='/titles/:page?' component={Titles} />
		<PrivateRoute path='/change-log/:page?' component={ReportsActivity}/>
		<PrivateRoute path='/map' component={MapPage}/>
		<PrivateRoute path='/contacts/:page?' component={CompanyContacts}/>
		<PrivateRoute path='/activity/:page?' component={ScheduleInfos} />
		<PrivateRoute path='/shippingvendors/:page?' component={ShippingVendors} />
		<PrivateRoute path='/correspondencetypes/:page?' component={CorrespondenceTypes} />
		<PrivateRoute path='/outcomeTypeCounters/:page?' component={OutcomeTypeCounters} />
		<PrivateRoute path='/call-editor/:id' component={CallEditor} />
		<PrivateRoute path='/global-settings' component={Settings} />
	</Switch>
</Layout>;
