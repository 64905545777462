import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';


export interface EmployeeCommentsObjectState {
	item: EmployeeCommentsObject | null
}

export interface EmployeeCommentsObject {
	id: number;
	objectType: string;
	caption: string;
}

export enum TypeKeys {
	SETVALUE = "SETCOMMENTSOBJECT",
}

export interface RequestPagesAction {
	type: TypeKeys.SETVALUE;
	item: EmployeeCommentsObject | null;
}

type KnownPageAction = RequestPagesAction;


export interface EmployeeCommentsObjectCreators {
	setCommentsObject: (item: EmployeeCommentsObject | null) =>  AppThunkAction<KnownPageAction>;
}

export const actionCreators = {
	setCommentsObject: (item: EmployeeCommentsObject | null): AppThunkAction<KnownPageAction> => (dispatch, getState) => {
		dispatch({ type: TypeKeys.SETVALUE, item: item });
	}
};

export const reducer: Reducer<EmployeeCommentsObjectState> = (state: EmployeeCommentsObjectState, incomingAction: Action) => {
	const action = incomingAction as KnownPageAction;
	switch (action.type) {
		case TypeKeys.SETVALUE:
			state = {item: action.item};
			return state;
	}

	return state || {
		pages: { item: null }
	};
};