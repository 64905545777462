import * as React from 'react';


interface SpinnerProps {
	className?: string;
	caption?: string;
}

export const Loading: React.SFC<SpinnerProps> = ({ className = 'page-loading', caption = 'Loading'}) => {
	return <div className={`loading-component ${className}`}>
		<div className="spinner">
			<div className="double-bounce1"></div>
			<div className="double-bounce2"></div>
		</div>
		<div className="loading-component__caption">
			{caption}
		</div>
	</div>
};
