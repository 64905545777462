import * as React from "react";
import {connect} from "react-redux";
import {ApplicationState} from "@app/store";
import {bindActionCreators} from "redux";
import * as ChatsState from "@app/store/Chats";
import {ChatsActionCreators} from "@app/store/Chats";
import {List} from "@common/typescript/objects/List";
import {Chat, ChatMessage, ChatMessageAccess, ChatUser} from "@app/objects/Chat";
import {User} from "@app/objects/User";

import * as signalR from "@aspnet/signalr";
import * as LoginState from "@app/store/Login";
import {LoginActionCreators} from "@app/store/Login";
import {RouteComponentProps} from "react-router";
import {NotificationAction} from "@common/typescript/objects/NotificationAction";

interface ReduxProps {
	chats: List<Chat>,
	user: User
}

interface Actions {
	actions: ChatsActionCreators<User, ApplicationState>,
	loginActions: LoginActionCreators
}

interface Notification {
	action: NotificationAction;
	data: any;
	id: number;
	kind: 0;
	service: boolean;
	show: boolean;
	time: number;
	objectType: string;
	objectId: number;
	user: number;
	usr: User;
}

type Props = ReduxProps & Actions & RouteComponentProps<{}>;

class SignalR extends React.Component<Props, {}> {
	componentDidMount() {
		const user = this.props.user;
		
		const connection = new signalR.HubConnectionBuilder()
			.withUrl("/notificationHub")
			.build();

		connection.start().catch(err => document.write(err));

		connection.on("handle", (notification: Notification) => {
			switch (notification.objectType) {
				case "ChatMessage":
					const chatMessage = notification.data as ChatMessage;
					
					const isOwnMessage: boolean = user.id == chatMessage.userId;
					
					this.props.actions.addMessage(chatMessage, !isOwnMessage);
					
					if(!isOwnMessage) {
						this.props.loginActions.updateUser({unviewedMessagesCount: user.unviewedMessagesCount + 1 })
					}
					break;
				case "ChatMessageAccess":
					const chatMessageAccess = notification.data as ChatMessageAccess;
					if(user.id === chatMessageAccess.userId && chatMessageAccess.viewed && user.unviewedMessagesCount > 0) {
						this.props.loginActions.updateUser({unviewedMessagesCount: user.unviewedMessagesCount - 1});
					}
					break;
				case "Chat":
					this.props.actions.addChat(notification.data as Chat);
					break;
				case "Init":
				case "UserSession":
					this.props.loginActions.setUserAndSession(notification.data.user as User, notification.data.guid as string);
					break;
				case "ChatUser":
					if(notification.action === NotificationAction.Add) {
						this.props.actions.addUserToChat(notification.data as ChatUser);
					} else if(notification.action === NotificationAction.Delete) {
						if((notification.data as ChatUser).userId === user.id) {
							this.props.actions.removeChat((notification.data as ChatUser).chatId)
						} else {
							this.props.actions.removeUserFromChat(notification.data as ChatUser);
						}
					}
					break;
					
			}
		});
	}
	
	render() {
		return null
	}
}

export default connect(
	(state: ApplicationState) => {
		return {
			chats: state.chats.chats,
			user: state.login.user
		}
	},
	(dispatch) => {
		return {
			actions: bindActionCreators(ChatsState.getActionCreators(), dispatch),
			loginActions: bindActionCreators(LoginState.getActionCreators(), dispatch)
		}
	}
)(SignalR as any);