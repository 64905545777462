
import * as Recover from './Recover';
import * as Dashboard from './Dashboard';
import * as GlobalSettings from './GlobalSettings';
import * as Item from './Item';
import * as Items from './ItemList';
import * as Reports from './Reports';


import {User} from "@app/objects/User";

import {BaseApplicationState, BaseAppThunkAction, baseReducers} from "@common/react/store";
import {Reducer, ReducersMapObject} from "redux";
import {Employee} from "@app/objects/Employee";
import {Injury, InjuryFile, InjuryPendingReason, InjuryType} from "@app/objects/Injury";
import {Company, CompanyContact, CompanyPhone} from '@app/objects/Company';
import {Language} from "@app/objects/Language";
import {CompanyType} from "@app/objects/CompanyType";
import {InjuryFilter} from "@app/objects/InjuryFilter";
import {Call, WeekDayCalls} from '@app/objects/Call';
import Occupation from "@app/objects/Occupation";
import {Question} from "@app/objects/Question";
import {eventsReducer} from "@app/store/Event";
import {injuryFilesReducer} from "@app/store/InjuryFile";
import {Action} from "@app/objects/Action";
import {StatusReport} from "@app/objects/StatusReport";
import {CallLog} from "@app/objects/CallLog";
import {FollowUpType} from "@app/objects/FollowUpType";
import {Group} from "@app/objects/Group";
import { EmailAccount } from "@app/objects/EmailAccount";
import {OutcomeType} from "@app/objects/OutcomeType";
import {CommentsState} from "@common/react/store/Comments";
import {Template} from "@app/objects/Template";

import {getReducer as getCommentsReducer} from "@common/react/store/Comments";
import {injuryJobsReducer} from "@app/store/InjuryJobs";
import {InjuryItemsState} from "@app/store/Injury";
import Schedule from "@app/objects/Schedule";
import {injuryStatusHistoryReducer} from "@app/store/InjuryStatusHistory";
import {StatusHistory} from "@app/objects/StatusHistory";
import {Reminder} from "@app/objects/Reminder";
import {injuryReminderReducer} from "@app/store/InjuryReminder";
import {injuryCorrspondenceReducer} from "@app/store/InjuryCorrespondence";
import {EmployeeCommentsObjectState} from "@app/store/EmployeeCommentsObject";

import {reducer as employeeCommentsObjectReducer} from "@app/store/EmployeeCommentsObject";
import {InjuryEmail} from "@app/objects/InjuryEmail";
import EmailSignature from "@app/objects/EmailSignature";

import {ChatsState} from "@app/store/Chats";

import {getReducer as getChatsReducer} from "@app/store/Chats";
import QuartzJob from "@app/objects/QuartzJob";
import Alert from "@app/objects/alert";
import {Title} from "@app/objects/Title";
import ScheduleContact from "@app/objects/ScheduleContact";
import {CaseByState} from "@app/objects/CaseByState";
import {RecentCase} from "@app/objects/RecentCase";
import ScheduleInfo from "@app/objects/ScheduleInfo";
import { ShippingVendor } from "@app/objects/ShippingVendor";
import { Correspondence } from "@app/objects/Correspondence";
import { CorrespondenceType } from "@app/objects/CorrespondenceType";
import InjuryCounter from "@app/objects/InjuryCounter";
import { OutcomeTypeCounter } from "@app/objects/OutcomeTypeCounter";
import {injuryCallLogsReducer} from "@app/store/InjuryCallLogs";
import { EmailThread } from '@app/objects/EmailThread';
import { EmailFolder } from '@app/objects/EmailFolder';
import { SpamFilter } from '@app/objects/SpamFilter';
import { ProjectType } from '@app/objects/ProjectType';
import { Project } from '@app/objects/Project';
import { SessionImage } from '@app/objects/SessionImage';
import { ProjectTask } from '@app/objects/ProjectTask';
import { ProjectSession } from '@app/objects/ProjectSession';
import { ProjectAudit } from '@app/objects/ProjectAudit';
import { TaskList } from '@app/objects/TaskList';
import { Holiday } from '@app/objects/Holiday';
import { ScheduleFilter } from '@app/objects/ScheduleFilter';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<User> {
	alerts: Items.ItemsState<Alert>;

	call: Item.ItemState<Call>;
	calls: Items.ItemsState<WeekDayCalls>;
	callLog: Item.ItemState<CallLog>;
	callLogs: Items.ItemsState<CallLog>;
	casesByState: Item.ItemState<CaseByState>;
	chats: ChatsState;
	companies: Items.ItemsState<Company>;
	company: Item.ItemState<Company>;
	companyContacts: Items.ItemsState<CompanyContact>;
	companyPhones: Items.ItemsState<CompanyPhone>;
	companyTypes: Items.ItemsState<CompanyType>;
	correspondence: Item.ItemState<Correspondence>;
	correspondenceTypes: Items.ItemsState<CorrespondenceType>;
	
	dashboard: Dashboard.DashboardState;
	dashboardFollowUps: Item.ItemState<Reminder>;
	dashBoardAlerts: Item.ItemState<Alert>;
	
	emailSignature: Item.ItemState<EmailSignature>;
	employe: Item.ItemState<Employee>;
	employees: Items.ItemsState<Employee>;
	employeeCommentsObject: EmployeeCommentsObjectState;
	employeeCompanies: Items.ItemsState<Company>;
	events: InjuryItemsState<Event>;
	
	injuryCallLogs: InjuryItemsState<CallLog>;
	injuryCounters: Item.ItemState<InjuryCounter>;
	injuryCorrespondence: InjuryItemsState<Correspondence>;
    injuryEmail: Item.ItemState<InjuryEmail>;
    emailThread: Item.ItemState<EmailThread>;
    emailAccount: Item.ItemState<EmailAccount>;
    injuryEmails: Items.ItemsState<InjuryEmail>;
    emailThreads: Items.ItemsState<EmailThread>;
    emailFolders: Items.ItemsState<EmailFolder>;
	injuryFiles: InjuryItemsState<InjuryFile>;
	injuryFilters: Items.ItemsState<InjuryFilter>;
	scheduleFilters: Items.ItemsState<ScheduleFilter>;
	injuryJobs: InjuryItemsState<Schedule>;
	injuryPendingReasons: Items.ItemsState<InjuryPendingReason>;
	injuryReports: Reports.ReportsState;
	injuryReminder: InjuryItemsState<Reminder>;
	injuryStatusHistory: InjuryItemsState<StatusHistory>;
    injuryTypes: Items.ItemsState<InjuryType>;
    projectTypes: Items.ItemsState<ProjectType>;
    projects: Items.ItemsState<Project>;
    projectTasks: Items.ItemsState<ProjectTask>;
    projectTask: Item.ItemState<ProjectTask>;
    taskLists: Items.ItemsState<TaskList>;
    projectSessions: Items.ItemsState<ProjectSession>;
    projectAudits: Items.ItemsState<ProjectAudit>;
    sessionImages: Items.ItemsState<SessionImage>;
	
	followUpTypes: Items.ItemsState<FollowUpType>;
	groups: Items.ItemsState<Group>;
    emailAccounts: Items.ItemsState<EmailAccount>;
    spamFilters: Items.ItemsState<SpamFilter>;

	globalSettings: GlobalSettings.GlobalSettingsState;
	
	languages: Items.ItemsState<Language>;
	lastActions: Item.ItemState<Action>;
	lastOpenedCases: Item.ItemState<RecentCase>;
	
	mapCompanies: Items.ItemsState<Company>;
	medicalRestrictions: CommentsState<User>;

	occupations: Items.ItemsState<Occupation>;
	outcomeTypes: Items.ItemsState<OutcomeType>;
	outcomeTypeCounters: Items.ItemsState<OutcomeTypeCounter>;

	questions: Items.ItemsState<Question>;
	quartzJobs: Items.ItemsState<QuartzJob>;
	
	reports: Items.ItemsState<Injury>;
	report: Item.ItemState<Injury>;
	recover: Recover.RecoverState;
	
	schedules: Items.ItemsState<Schedule>;
	scheduleContacts: Items.ItemsState<ScheduleContact>;
	scheduleInfos: Items.ItemsState<ScheduleInfo>;
	shippingVendors: Items.ItemsState<ShippingVendor>;
	statusReport: Item.ItemState<StatusReport>;
	statusReports: Items.ItemsState<StatusReport>

	templates: Items.ItemsState<Template>;
	template: Item.ItemState<Template>;
	titles: Items.ItemsState<Title>;
	
	user: Item.ItemState<User>;
	users: Items.ItemsState<User>;
	userCompanies: Items.ItemsState<Company>;
	holiday: Item.ItemState<Holiday>;
	holidays: Items.ItemsState<Holiday>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.

type Reducers = Partial<{
	[key in keyof ApplicationState]: Reducer<any>;
}>

export const reducers: Reducers = {
	...baseReducers,

	alerts: Items.getReducer<Alert>('alerts'),
	
	casesByState: Items.getReducer<CaseByState>('casesByState'),
	call: Item.getReducer<Call>('call'),
	calls: Items.getReducer<Call>('calls'),
	callLogs: Items.getReducer<CallLog>('callLogs'),
	callLog: Item.getReducer<Company>('callLog'),
	chats: getChatsReducer(),
	companies: Items.getReducer<Company>('companies'),
	company: Item.getReducer<Company>('company'),
	companyContacts: Items.getReducer<CompanyContact>('companyContacts'),
	companyPhones: Items.getReducer<CompanyPhone>('companyPhones'),
	companyTypes: Items.getReducer<CompanyType>('companyTypes'),
	correspondence: Item.getReducer<Correspondence>('correspondence'),
	correspondenceTypes: Items.getReducer<CorrespondenceType>('correspondenceTypes'),
	
	dashboard: Dashboard.reducer,
	dashBoardAlerts: Items.getReducer<Alert>('dashBoardAlerts'),
	dashboardFollowUps: Items.getReducer<Reminder>('dashboardFollowUps'),

	emailSignature: Item.getReducer<EmailSignature>('emailSignature'),
	employe: Item.getReducer<Employee>('employe'),
	employees: Items.getReducer<Employee>('employees'),
	employeeCommentsObject: employeeCommentsObjectReducer,
	employeeCompanies: Items.getReducer<Company>('employeeCompanies'),
	events: eventsReducer,


	followUpTypes: Items.getReducer<FollowUpType>('followUpTypes'),
    groups: Items.getReducer<Group>('groups'),
    emailAccounts: Items.getReducer<EmailAccount>('emailAccounts'),
    spamFilters: Items.getReducer<SpamFilter>('spamFilters'),

	injuryCallLogs: injuryCallLogsReducer,
	injuryCorrespondence: injuryCorrspondenceReducer,
	injuryCounters: Items.getReducer<InjuryCounter>('injuryCounters'),
    injuryEmail: Item.getReducer<InjuryEmail>('injuryEmail'),
    emailThread: Item.getReducer<EmailThread>('emailThread'),
    emailAccount: Item.getReducer<EmailAccount>('emailAccount'),
    injuryEmails: Items.getReducer<InjuryEmail>('injuryEmails'),
    emailThreads: Items.getReducer<EmailThread>('emailThreads'),
    emailFolders: Items.getReducer<EmailFolder>('emailFolders'),
	injuryFiles: injuryFilesReducer,
	injuryFilters: Items.getReducer<InjuryFilter>('injuryFilters'),
	scheduleFilters: Items.getReducer<ScheduleFilter>('scheduleFilters'),
	injuryJobs: injuryJobsReducer,
	injuryPendingReasons: Items.getReducer<InjuryPendingReason>('injuryPendingReasons'),
	injuryReports: Reports.reducer,
	injuryReminder: injuryReminderReducer,
	injuryStatusHistory: injuryStatusHistoryReducer,
	injuryTypes: Items.getReducer<InjuryType>('injuryTypes'),
	projectTypes: Items.getReducer<ProjectType>('projectTypes'),
	projects: Items.getReducer<Project>('projects'),
    projectTasks: Items.getReducer<ProjectTask>('projectTasks'),
    projectTask: Item.getReducer<ProjectTask>('projectTask'),
    taskLists: Items.getReducer<TaskList>('taskLists'),
    projectSessions: Items.getReducer<ProjectSession>('projectSessions'),
    projectAudits: Items.getReducer<ProjectAudit>('projectAudits'),
    sessionImages: Items.getReducer<SessionImage>('sessionImages'),

	globalSettings: GlobalSettings.reducer,

	languages: Items.getReducer<Language>('languages'),
	lastActions: Items.getReducer<Action>('lastActions'),
	lastOpenedCases: Items.getReducer<RecentCase>('lastOpenedCases'),
	
	mapCompanies: Items.getReducer<Company>('mapCompanies'),
	medicalRestrictions: getCommentsReducer('medicalRestrictions'),
	
	occupations: Items.getReducer<Occupation>('occupations'),
	outcomeTypes: Items.getReducer<OutcomeType>('outcomeTypes'),
	outcomeTypeCounters: Items.getReducer<OutcomeTypeCounter>('outcomeTypeCounters'),
	
	quartzJobs: Items.getReducer<QuartzJob>('quartzJobs'),
	questions: Items.getReducer<Question>('questions'),
	
	recover: Recover.reducer,
	reports: Items.getReducer<Injury>('reports'),
	report: Item.getReducer<Injury>('report'),
	
	schedules: Items.getReducer<Schedule>('schedules'),
	scheduleInfos: Items.getReducer<ScheduleInfo>('scheduleInfos'),
	scheduleContacts: Items.getReducer<ScheduleContact>('scheduleContacts'),
	shippingVendors: Items.getReducer<ShippingVendor>('shippingVendors'),
	statusReport: Item.getReducer<StatusReport>('statusReport'),
	statusReports: Items.getReducer<StatusReport>('statusReports'),

	templates: Items.getReducer<Template>('templates'),
	template: Item.getReducer<Template>('template'),
	titles: Items.getReducer<Title>('titles'),
	holiday: Item.getReducer<Holiday>('holiday'),
	holidays: Items.getReducer<Holiday>('holidays'),
	user: Item.getReducer<User>('user'),
	users: Items.getReducer<User>('users'),
	userCompanies: Items.getReducer<Company>('userCompanies'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> extends BaseAppThunkAction<TAction, User, ApplicationState> {}
