import {BaseUser} from "@common/react/objects/BaseUser";
import {Company} from "@app/objects/Company";
import {Group} from "@app/objects/Group";
import { EmailAccount } from "./EmailAccount";
import { Employee } from "./Employee";
import { Injury } from "./Injury";

export enum UserRole {
	Admin = 1,
	Internal = 2,
    External = 3,
    RTWatHome = 4
}


export enum AgingReportType {
    TotalDaysInProgram = 1,
    ActualDaysWorked = 2
}

export interface CompanyUser {
	id: number;
	userId: number;
	user: User;
	companyId: number;
	company: Company;
}

export interface EmailAccountUser {
    id: number;
    userId: number;
    user: User;
    emailAccountId: number;
    emailAccount: EmailAccount;
}

export interface CompanyLink {
	id: number;
	name: string;
}

export interface User extends BaseUser
{
	role: UserRole;
	companyName: string;
    companies: CompanyUser[];
    emailAccounts: EmailAccountUser[];
	companyLinks?: CompanyLink[];
    agingReportType: AgingReportType;
    agingNumberOfDays: number;
	avatar: string;
	originalAvatar: string;
    groupId: number| null;
    group: Group;
    defaultEmailAccountId: number | null;
    defaultEmailAccount: EmailAccount;
    injuryId: number | null;
    injury: Injury;
    isAll: boolean;
    emailConfirmed: boolean;
	signature: string;
    emailSignature: string;
    canOpenWebsite: boolean;
    loggedIn: boolean;
    canOpenDesktopApp: boolean;
    canOpenWorkAtAnyTime: boolean;
}

export const isExternalUser = (user: User | null): boolean => user ? user.role === UserRole.External : false;