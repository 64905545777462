import * as React from 'react';

import {Menu} from '@app/components/UI/Menu/Menu';
import {MenuItem} from "@app/objects/MenuItem";
import {ApplicationState} from "@app/store";
import {RouteComponentProps, withRouter} from "react-router-dom";
import * as LoginState from "@app/store/Login";
import {connect} from "react-redux";
import {UserRole} from "@app/objects/User";

interface OwnProps {
	collapsed: boolean
}

const sortMenu = (a: MenuItem, b: MenuItem) => {
	if(a.name < b.name) { return -1; }
	if(a.name > b.name) { return 1; }
	return 0;
};

type ComponentProps = LoginState.LoginState & OwnProps & RouteComponentProps<{}>;

class LeftPanel extends React.Component<ComponentProps, {}> {
	externalUserMenu: MenuItem[];
	internalUserMenu: MenuItem[];
	adminUserMenu: MenuItem[];
    rTWatHomeUserMenu: MenuItem[];
	
	constructor(props) {
		super(props);
		
		
		this.externalUserMenu = [
			{
				path: 'employees',
				name: 'Employees'
			},
			{
				path: '',
				name: 'Reports',
				children: {
					count: 0,
					execution: 0,
					offset: 0,
                    list: [

                        {
                            path: 'alert-list',
                            name: 'Alerts'
                        },
						{
							path: 'injury-reports',
							name: 'All Cases'
						},
						{
							path: 'outcomeTypeCounters',
							name: 'Outcome Type Report'
						},
						{
							path: 'status-report-list',
							name: 'Status Reports'
						},
						{
							path: 'calls',
							name: 'Time Cards'
						},
					]
				}
			}
		];

        this.internalUserMenu = [

            {
                path: 'mailbox',
                name: 'MailBox'
            },
            {
                path: 'calendar',
                name: 'Calendar'
            },
			{
				path: 'employees',
				name: 'Employees'
			},
			{
				path: '',
				name: 'Reports',
				children: {
					count: 0,
					execution: 0,
					offset: 0,
					list: [
						{
							path: 'alert-list',
							name: 'Alerts'
						},
						{
							path: 'injury-reports',
							name: 'All Cases'
						},
						{
							path: 'calls',
							name: 'Time Cards'
						},
						{
							path: 'status-report-list',
							name: 'Status Reports'
						},
						{
							path: 'outcomeTypeCounters',
							name: 'Outcome Type Report'
						}
					].sort(sortMenu)
				}
			},
			{
				path: '',
				name: 'Admin',
				children: {
					count: 3,
					execution: 0,
					offset: 0,
					list: [
						{
							path: 'companies',
							name: 'Companies'
						},
						{
							path: 'contacts',
							name: 'Contacts'
						}
					].sort(sortMenu)
				}

			}
		];
            /*[
                {
                    path: 'employees',
                    name: 'Employees'
                },
                {
                    path: '',
                    name: 'Reports',
                    children: {
                        count: 0,
                        execution: 0,
                        offset: 0,
                        list: [
                            {
                                path: 'alert-list',
                                name: 'Alerts'
                            },
                            {
                                path: 'injury-reports',
                                name: 'All Cases'
                            },
                            {
                                path: 'calls',
                                name: 'Time Cards'
                            },
                            {
                                path: 'status-report-list',
                                name: 'Status Reports'
                            },
                            {
                                path: 'outcomeTypeCounters',
                                name: 'Outcome Type Report'
                            }
                        ].sort(sortMenu)
                    }
                },
                {
                    path: '',
                    name: 'Admin',
                    children: {
                        count: 3,
                        execution: 0,
                        offset: 0,
                        list: [
                            {
                                path: 'user-list',
                                name: 'Users'
                            },
                            {
                                path: 'companies',
                                name: 'Companies'
                            },
                            {
                                path: 'contacts',
                                name: 'Contacts'
                            },
                            {
                                path: 'email-list',
                                name: 'Emails'
                            }
                        ].sort(sortMenu)
                    }

                }
            ];*/

        this.adminUserMenu = [
            
            {
                path: 'mailbox',
                name: 'MailBox'
            },
            {
                path: 'calendar',
                name: 'Calendar'
            }, {
                path: 'kanban',
                name: 'Projects Board'
            },
			{
				path: 'employees',
				name: 'Employees'
			},
			{
				path: '',
				name: 'Reports',
				children: {
					count: 0,
					execution: 0,
					offset: 0,
					list: [
						{
							path: 'activity',
							name: 'Quality Сontrol'
						},
						{
							path: 'alert-list',
							name: 'Alerts'
						},
						{
							path: 'injury-reports',
							name: 'All Cases'
						},
						{
							path: 'calls',
							name: 'Time Cards'
						},
						{
							path: 'change-log',
							name: 'Audit Log'
						},
						{
							path: 'status-report-list',
							name: 'Status Reports'
						},
						{
							path: 'outcomeTypeCounters',
							name: 'Outcome Type Report'
						}
					].sort(sortMenu)
				}
			},
			{
				path: '',
				name: 'Admin',
				children: {
					count: 3,
					execution: 0,
					offset: 0,
					list: [
						{
							path: 'user-list',
							name: 'Users'
						},
						{
							path: 'companies',
							name: 'Companies'
						},
						{
							path: 'contacts',
							name: 'Contacts'
                        },
                        {
                            path: 'groups',
                            name: 'Groups'
                        },
                        {
                            path: 'emailaccounts',
                            name: 'Email Accounts'
                        },
                        {
                            path: 'spamfilters',
                            name: 'Spam Filters'
                        },

                        {
                            path: '',
                            name: 'Projects',
                            children: {
                                count: 4,
                                execution: 0,
                                offset: 0,
                                list: [
                                    {
                                        path: 'projectcalendar',
                                        name: 'Calendar'
                                    },
                                    {
                                        path: 'projects',
                                        name: 'Projects'
                                    }, {
                                        path: 'taskLists',
                                        name: 'TaskLists'
                                    },{
                                        path: 'projectTasks',
                                        name: 'Tasks'
                                    },{
                                        path: 'projectSessions',
                                        name: 'Sessions'
                                    },{
                                        path: 'projectAudits',
                                        name: 'Audit'
                                    }, {
                                        path: 'projectTypes',
                                        name: 'Project Types'
                                    },
                                ]
                            }
                        },
						{
							path: '',
							name: 'Settings',
							children: {
								count: 4,
								execution: 0,
								offset: 0,
								list: [
									{
										path: 'language-list',
										name: 'Languages Spoken'
									},
									{
										path: 'holidays',
										name: 'Holidays'
									},
									{
										path: 'injurytypes',
										name: 'Injury Types'
									},
									{
										path: 'injurypendingreasons',
										name: 'Injury Pending Reasons'
									},
									{
										path: 'followUpTypes',
										name: 'Follow-up Types'
									},
									{
										path: 'occupations',
										name: 'Occupations'
									},
									{
										path: 'questions',
										name: 'Questions'
									},
									{
										path: 'outcometypes',
										name: 'Outcome Types'
									},
									{
										path: 'templates',
										name: 'Templates'
									},
									{
										path: 'titles',
										name: 'Titles'
									},
									{
										path: 'shippingVendors',
										name: 'Shipping Vendors'
									},
									{
										path: 'correspondenceTypes',
										name: 'Correspondence Types'
									},
									{
										path: 'global-settings',
										name: 'Global Settings'
									},
								].sort(sortMenu)
							}
						}
					].sort(sortMenu)
				}
				
			}
        ];

        this.rTWatHomeUserMenu = [
            {
                path: 'projectuserkanban',
                name: 'Dashboard'
            }, {
                path: 'projectTasks',
                name: 'Tasks'
            } 
                    
        ]
	}
	
	shouldComponentUpdate(nextProps: Readonly<ComponentProps>, nextState: Readonly<{}>, nextContext: any): boolean {
		return this.props.user !== nextProps.user || this.props.collapsed !== nextProps.collapsed;
	}

	render() {
		const {user, collapsed} = this.props;
		
		let menuItems: MenuItem[] = [];
		
		if(user) {
			switch (user.role) {
				case UserRole.Admin:
					menuItems = this.adminUserMenu;
					break;
				case UserRole.Internal:
					menuItems = this.internalUserMenu;
					break;
				case UserRole.External: 
					menuItems = this.externalUserMenu;
                    break;
                case UserRole.RTWatHome:
                    menuItems = this.rTWatHomeUserMenu;
                    break;
			}
		}

        if (this.props.user && this.props.user.role != UserRole.RTWatHome)
		menuItems = [{
			path: 'dashboard',
			name: 'Dashboard'
		}].concat(menuItems);
		
		return <div className={`left-panel hide-print ${collapsed ? 'collapsed' :''}`}>
					<div className="left-panel__inner">
						<div className="left-panel__logo">
							<img src={require('@images/logo.jpg')} alt=""/>
							
						</div>
						<Menu className="left-panel__menu" items={menuItems} basePath="/"/>
					</div>
				</div>
	}
}

export default withRouter(connect<LoginState.LoginState, {}, any>(
	(state: ApplicationState) => state.login,
	{}
)(LeftPanel));