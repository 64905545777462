import {User} from '@app/objects/User';

export function dateToLocaleCustom(t?: number):string {
	if (!t) return '';

	const date = new Date(t),
		year = date.getFullYear(),
		month = (`0${date.getMonth() + 1}`).slice(-2),
		day = (`0${date.getDate()}`).slice(-2);

	return `${month}/${day}/${year}`;
}

export function dateTimeToLocaleCustom(t?: number):string {
	if (!t) return '';
	
	return `${dateToLocaleCustom(t)} ${new Date(t).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
}

export function dateToStringCustom(t?: number):string {
	if (!t) return '';
	
	return dateToLocaleCustom(t + (new Date(t)).getTimezoneOffset() * 60 * 1000);
}

export function dateTimeToStringCustom(t?: number):string {
	if (!t) return '';
	
	return dateTimeToLocaleCustom(t + (new Date(t)).getTimezoneOffset() * 60 * 1000);
}

function transliterate(str: string): string {
	// tslint:disable-next-line:max-line-length
	const tr = 'A B V G D E ZH Z I Y K L M N O P R S T U F H C CH SH SCH  Y  E YU YA a b v g d e zh z i y k l m n o p r s t u f h c ch sh sch  y  e yu ya e e'.split(' ');
	let result = '';
	
	for (let i = 0; i < str.length; ++i) {
		const cc = str.charCodeAt(i);
		result += (cc === 1025 ? 'E' : (cc >= 1040 ? tr[cc - 1040] : str[i]));
	}
	return result;
}

export const stringToUrl: (str: string) => string = (str) => {
	return transliterate(str)
		.replace('[^0-9a-z -]+', '')
		.replace(' ', '-')
		.replace('\'', '')
		.toLowerCase();
};

export const getAvatar: (user: User) => string = (user) => {
	return `${user.avatar ? user.avatar : require('@images/avatar.jpg')}`;
};

export function enumNameToTitle(name: string):string {
	return name.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
}

export const getPopupContainer = triggerNode => triggerNode.parentNode as HTMLElement;