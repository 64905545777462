import {
	getInjuriesItemsReducer,
	getInjuryItemsActionCreators,
	getSelectedInjuryItemsActionCreators
} from "@app/store/Injury";

import Schedule from "@app/objects/Schedule";

const INJURY_ITEMS_ACTION = 'INJURY_FILE_ACTION';

//instead of dispatching action dispatch { type: INJURY_FILE_ACTION, injuryId: injuryId, action: action }
export const getInjuryFilesActionCreators = getInjuryItemsActionCreators('injuryFiles', INJURY_ITEMS_ACTION);

export const getSelectedInjuryFilesActionCreators = getSelectedInjuryItemsActionCreators('injuryFiles', INJURY_ITEMS_ACTION);

export const injuryFilesReducer = getInjuriesItemsReducer<Schedule>('injuryFiles', INJURY_ITEMS_ACTION);