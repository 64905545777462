import * as React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import * as LoginState from "@app/store/Login";
import {ApplicationState} from "@app/store";
import {connect} from "react-redux";
import Logout from '@common/react/components/UI/Logout/Logout';
import {UserRole} from "@app/objects/User";
import HeaderTransmutation from "@app/components/UI/Header/HeaderTransmutation";
import {isMobile} from "@app/components/Utils/Utils";
import { Modal, Button } from 'antd';
import moment from "moment";

import { request } from "@app/components/Api";

type HeaderProps = LoginState.LoginState & RouteComponentProps<{}>;


interface HeaderState {
	isMenuOpen: boolean;
    showTerms: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
	constructor(props: HeaderProps) {
		super(props);
		
		this.state = {
            isMenuOpen: false,
            showTerms: false

		};

		this.toggleMenu = this.toggleMenu.bind(this);
        this.agreeToTerms = this.agreeToTerms.bind(this);
	}

    componentDidMount() {
        if (this.props.user && this.props.user.role === UserRole.RTWatHome) {
            this.setState({ showTerms: true })
        } 
    }

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
			this.setState({isMenuOpen: false});
		}
	}
	
	render() {
		const {user, transmuted} = this.props;
		
        const isAdmin: boolean = !!user && user.role === UserRole.Admin;
        const isNotWAH: boolean = !!user && user.role !== UserRole.RTWatHome;

        return isNotWAH ? <header className={`site-header ${isAdmin ? 'site-header__admin' : ''}`}>
                <div className="container-fluid">
                    <div className="clearfix">
                        <div className="pull-right site-header__user-block">
                            {user && <React.Fragment>
                                {!isMobile() && <React.Fragment>
                                    {(isAdmin || transmuted) && <React.Fragment>
                                        <HeaderTransmutation transmuted={transmuted} />
                                        |
								</React.Fragment>}
                                    <React.Fragment>
                                        <i className="fa fa-refresh reload" onClick={e => window.location.reload()}></i>
                                        |
								</React.Fragment>
                                </React.Fragment>}
                                {isAdmin && <React.Fragment>
                                    <Link to="/map">
                                        <i className="fa fa-map" aria-hidden="true"></i>
                                    </Link>
                                    |
							</React.Fragment>}
                                {user.role !== UserRole.External && <React.Fragment>
                                    <Link to="/chats" className="site-header__chats">
                                        <i className="fa fa-comments" aria-hidden="true"></i>
                                        {user.unviewedMessagesCount > 0 &&
                                            <div className="badge site-header__chats-badge">{user.unviewedMessagesCount}</div>}
                                    </Link>
                                    |
							</React.Fragment>}
                                <Link to="/profile">
                                    <div className="user-block__avatar" style={{ backgroundImage: `url(${user.avatar ? user.avatar : require('@images/avatar.jpg')})` }}></div>
                                    <span>{user.firstName ? `${user.firstName || ''} ${user.lastName || ''}` : user.email}</span>
                                </Link>
                                |
							<Logout />
                            </React.Fragment>}
                        </div>
                    </div>
                </div>
        </header> : <header className={`site-header ${isAdmin ? 'site-header__admin' : ''}`}>
            <div className="container-fluid">
                    <div className="clearfix">
                        <span style={{
                            fontSize: 15,
                            marginTop: "10px",
    display: "inline-block" }}>Contact Us -Ph:855-254-3969 / email: support@varzis.com</span>
                                                    <div className="pull-right site-header__user-block">
                            <a>
                                <i className="fa fa-help" aria-hidden="true"></i>
                                User Guide / Video
                            </a>
                            
                            <Logout />
                    </div>
                </div>
                </div>
                <Modal
                    title={'should read "Terms and Conditions"'}
            visible={this.state.showTerms}
                    width={600}
                    bodyStyle={{ height: "60vh", overflow:"scroll" }}
                    footer={<Button className="btn btn-primary" onClick={e => { this.agreeToTerms() }}>{'Agree'}</Button>}
            destroyOnClose={true}>
                <section className="pdf_page">
                    <div className="textlayer">
                        <section className="pdf_page">
                            <div className="textlayer">
                                <p><strong><u>TERMS OF SERVICE</u></strong></p>
                                <p>These terms of service are the agreement (the &ldquo;Agreement&rdquo;) between you and VCC, a Limited Liability Company with a principal place of business at 2805 East Oakland Park Blvd #376 Ft Lauderdale, FL 33306 regarding your access and use of VCC&rsquo;s websites at <a href="https://www.varzis.com">https://www.varzis.com</a> and <a href="https://www.lightdutyathome.com">https://www.lightdutyathome.com</a>.</p>
                                <p>Please read this Agreement carefully before you start to use the Website. Your access and/or use of the Website constitutes your acceptance and agreement to be bound by all the policies and terms within this Agreement. If you do not agree to this Agreement, please refrain from accessing or using the Website.</p>
                                <p>&nbsp;</p>
                                <p><strong>ACCESSING THE WEBSITE</strong></p>
                                <p>&nbsp;</p>
                                <p>Access to VCC&rsquo;s site is permitted on a temporary basis, and VCC reserves the right to withdraw or amend the service VCC provides on the Website without notice (see below). VCC will not be liable if for any reason the Website is unavailable at any time or for any period.</p>
                                <p>From time to time, VCC may restrict access to some parts of the Website, or the entire Website, to users who have or have not registered with VCC.</p>
                                <p>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of VCC&rsquo;s security procedures, you must treat such information as confidential, and you must not disclose it to any third party. VCC has the right to disable any user identification code or password, whether chosen by you or allocated by VCC, at any time, if in VCC&rsquo;s opinion you have failed to comply with any of the provisions of this Agreement.</p>
                                <p>You are responsible for making all arrangements necessary for you to have access to the Website.&nbsp; You are also responsible for ensuring that all persons who access the Website through your internet connection are aware of these terms, and that they comply with them.&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>When using VCC&rsquo;s site, you must comply with the provisions of the Website Acceptable Use Policy.</p>
                                <p>&nbsp;</p>
                                <p><strong>WEBSITE ACCEPTABLE USE POLICY</strong></p>
                                <p>This acceptable use policy sets out the terms between you and VCC under which you may access the Website. This acceptable use policy applies to all users of, and visitors to, the Website.</p>
                                <p><strong>Prohibited uses</strong></p>
                                <p>You may use the Website only for lawful purposes.&nbsp; You may not use the Website:</p>
                                <ul>
                                    <li>In any way, that breaches any applicable local, national or international law or regulation.</li>
                                    <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
                                    <li>For the purpose of harming or attempting to harm minors in any way.</li>
                                    <li>To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).</li>
                                    <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                                </ul>
                                <p>You also agree:</p>
                                <ul>
                                    <li>Not to reproduce, duplicate copy or re-sell any part of the Website in contravention of the provisions of VCC&rsquo;s terms of website use.</li>
                                    <li>Not to access without authority, interfere with, damage or disrupt:</li>
                                    <ul>
                                        <li>any part of the Website;</li>
                                        <li>any equipment or network on which the Website is stored;</li>
                                        <li>any software used in the provision of the Website; or</li>
                                        <li>any equipment or network or software owned or used by any third party.</li>
                                    </ul>
                                </ul>
                                <p><strong>Suspension and Termination</strong></p>
                                <p>VCC will determine, in VCC&rsquo;s discretion, whether there has been a breach of this acceptable use policy through your use of the Website.&nbsp; When a breach of this policy has occurred, VCC may take such action, as VCC deems appropriate.&nbsp;</p>
                                <p>Failure to comply with this acceptable use policy constitutes a material breach of the&nbsp;terms of this Agreement, and may result in VCC&rsquo;s taking all or any of the following actions:</p>
                                <ul>
                                    <li>Immediate, temporary or permanent withdrawal of your right to use the Website.</li>
                                    <li>Immediate, temporary or permanent removal of any posting or material uploaded by you to the Website.</li>
                                    <li>Issue of a warning to you.</li>
                                    <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                                    <li>Further legal action against you.</li>
                                    <li>Disclosure of such information to law enforcement authorities as VCC reasonably feel is necessary.</li>
                                </ul>
                                <p>VCC excludes liability for actions taken in response to breaches of this acceptable use policy.&nbsp; The responses described in this policy are not limited, and VCC may take any other action VCC reasonably deems appropriate.</p>
                                <p><strong>PRIVACY NOTICE</strong></p>
                                <p>This privacy notice discloses the privacy practices for&nbsp;the Website. This privacy notice applies solely to information collected by the Website. It will notify you of the following:</p>
                                <ol>
                                    <li>What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.</li>
                                    <li>What choices are available to you regarding the use of your data.</li>
                                    <li>The security procedures in place to protect the misuse of your information.</li>
                                    <li>How you can correct any inaccuracies in the information.</li>
                                </ol>
                                <p><strong>Information Collection, Use, and Sharing</strong>&nbsp;</p>
                                <p>VCC is the sole owners of the information collected on this site. VCC only has access to/collect information that you voluntarily give us via email or other direct contact from you. VCC will not sell or rent this information to anyone.</p>
                                <p>VCC will use your information to respond to you, regarding the reason you contacted us. VCC will not share your information with any third party outside of VCC&rsquo;s organization, other than as necessary to fulfill your request, e.g. to ship an order.</p>
                                <p>Unless you ask us not to, VCC may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.</p>
                                <p><strong>Your Access to and Control Over Information</strong>&nbsp;</p>
                                <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on VCC&rsquo;s website:</p>
                                <ul>
                                    <li>See what data VCC has about you, if any.</li>
                                    <li>Change/correct any data VCC has about you.</li>
                                    <li>Have VCC delete any data VCC has about you.</li>
                                    <li>Express any concern you have about VCC&rsquo;s use of your data.</li>
                                </ul>
                                <p><strong>Security</strong></p>
                                <p><strong>&nbsp;</strong><br /> VCC takes precautions to protect your information. When you properly submit sensitive information via the website, your information is protected both online and offline.</p>
                                <p>Wherever VCC collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the Web page.</p>
                                <p>While VCC uses encryption to protect sensitive information transmitted online, VCC also protects your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which VCC stores personally identifiable information are kept in a secure environment.</p>
                                <p>If you feel that VCC is not abiding by this privacy policy, you should contact us immediately via telephone at&nbsp;(888) 241-9454 or&nbsp;via email to info@Varnercc.com.</p>
                                <p><strong>Registration</strong><strong>&nbsp;</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>In order to use certain sections of the Website, you must first complete the registration form. During registration you are required to give certain information (such as name and email address). This information is used to contact you about the products/services on the Website in which you have expressed interest. At your option, you may also provide demographic information (such as gender or age) about yourself, but it is not required.</p>
                                <p><br /> <strong>Cookies</strong><strong>&nbsp;</strong></p>
                                <p><br /> VCC uses "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to the Website and identify repeat visitors to the Website. For instance, when VCC uses a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on the Website. Cookies also enable us to track and target the interests of VCC&rsquo;s users to enhance the experience on the Website. Usage of a cookie is in no way linked to any personally identifiable information on the Website.</p>
                                <p>Some of VCC&rsquo;s business partners may use cookies on the Website. However, VCC has no access to or control over these cookies.</p>
                                <p><strong>Links</strong><strong>&nbsp;</strong></p>
                                <p><br /> This website may contain links to other sites. Please be aware that VCC is not responsible for the content or privacy practices of such other sites. VCC encourages VCC&rsquo;s users to be aware when they leave the Website and to read the privacy statements of any other site that collects personally identifiable information.</p>
                                <p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>VCC is the owner or the licensee of all intellectual property rights in the Website, and in the material published on it.&nbsp; Those works are protected by copyright laws and treaties around the world.&nbsp; All such rights are reserved.</p>
                                <p>VCC&rsquo;s status (and that of any identified contributors) as the authors of material on the Website must always be acknowledged.</p>
                                <p>You must not use any part of the materials on the Website for commercial purposes without obtaining a license to do so from us or VCC&rsquo;s licensors.</p>
                                <p>If you print off, copy or download any part of the Website in breach of this Agreement, your right to use the Website will cease immediately and you must, at VCC&rsquo;s option, return or destroy any copies of the materials you have made.</p>
                                <p>&nbsp;</p>
                                <p><strong>RELIANCE ON INFORMATION POSTED</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>Commentary and other materials posted on the Website are not intended to amount to advice on which reliance should be placed.&nbsp; VCC therefore disclaims all liability and responsibility arising from any reliance placed on such materials by any visitor to the Website, or by anyone who may be informed of any of its contents.</p>
                                <p>&nbsp;</p>
                                <p><strong>THE WEBSITE CHANGES REGULARLY</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>VCC aims to update the Website regularly, and may change the content at any time. If the need arises, VCC may suspend access to the Website, or close it indefinitely. Any of the material on the Website may be out of date at any given time, and VCC is under no obligation to update such material.</p>
                                <p>&nbsp;</p>
                                <p><strong>VCC&rsquo;S LIABILITY</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>The material displayed on the Website is provided without any guarantees, conditions or warranties as to its accuracy. To the extent permitted by law, VCC, other members of VCC&rsquo;s group of companies, affiliates, officers, directors, employees, and third parties connected to us hereby expressly exclude:</p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li>All conditions, warranties and other terms, which might otherwise be implied by statute, common law or the law of equity.</li>
                                </ul>
                                <p>&nbsp;</p>
                                <ul>
                                    <li>Any liability for any direct, indirect or consequential loss or damage incurred by any user in connection with the Website or in connection with the use, inability to use, or results of the use of the Website, any websites linked to it and any materials posted on it, including, without limitation any liability for:</li>
                                </ul>
                                <p>&nbsp;</p>
                                <ul>
                                    <li>loss of income or revenue;</li>
                                    <li>loss of business;</li>
                                    <li>loss of profits or contracts;</li>
                                    <li>loss of anticipated savings;</li>
                                    <li>loss of data;</li>
                                    <li>loss of goodwill;</li>
                                    <li>wasted management or office time; and</li>
                                </ul>
                                <p>for any other loss or damage of any kind, however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable, provided that this condition shall not prevent claims for loss of or damage to your tangible property or any other claims for direct financial loss that are not excluded by any of the categories set out above.</p>
                                <p>&nbsp;</p>
                                <p><strong>W</strong><strong>arranty Disclaimer</strong></p>
                                <p>&nbsp;</p>
                                <p>YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE WEBSITE AND THE SERVICES FOUND AT THE WEBSITE WILL BE AT YOUR OWN RISK AND THAT THE WEBSITE AND THE SERVICES FOUND AT THE WEBSITE ARE PROVIDED "AS IS", "AS AVAILABLE" AND "WITH ALL FAULTS." VERNER, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, CONDITIONS, REPRESENTATIONS, STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES, CONDITIONS AND REPRESENTATIONS OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. VCC, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS MAKE NO WARRANTIES, CONDITIONS OR REPRESENTATIONS ABOUT (A) THE ABILITY OF THE SERVICES OR SOFTWARE TO PERFORM WITHOUT LIMITATION OR RESTRICTION IN ANY GIVEN ENVIRONMENT, (B) THE ACCURACY, COMPLETENESS, OR CONTENT OF THE WEBSITE OR THE SERVICES OR SOFTWARE, (C) THE ACCURACY, COMPLETENESS, OR CONTENT OF ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THE WEBSITE, AND/OR (D)) THE SERVICES FOUND AT THE WEBSITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THE WEBSITE, AND VERNER ASSUMES NO LIABILITY OR RESPONSIBILITY FOR THE SAME.</p>
                                <p>&nbsp;</p>
                                <p><strong>No Oral Warranties and/or Advice</strong></p>
                                <p>&nbsp;</p>
                                <p>IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED BY VERNER, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS (INCLUDING WITHOUT LIMITATION ITS CALL CENTER OR VERNER SERVICE REPRESENTATIVES), WHETHER DIRECTLY OR INDIRECTLY, WILL (A) CONSTITUTE PERSONAL, LEGAL OR FINANCIAL ADVICE OR (B) CREATE A WARRANTY, CONDITION OR REPRESENTATION OF ANY KIND WITH RESPECT TO THE WEBSITE OR THE SERVICES FOUND AT THE WEBSITE. YOU SHOULD NOT RELY ON ANY SUCH INFORMATION OR ADVICE, AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO ITS SITUATION.</p>
                                <p>&nbsp;</p>
                                <p><strong>Survival of Disclaimer</strong></p>
                                <p>&nbsp;</p>
                                <p>THE FOREGOING DISCLAIMER OF REPRESENTATIONS, CONDITIONS AND WARRANTIES WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, AND WILL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS AGREEMENT OR YOUR USE OF THE WEBSITE OR THE SERVICES FOUND AT THE WEBSITE.</p>
                                <p>&nbsp;</p>
                                <strong><br /> </strong>
                                <p><strong>&nbsp;</strong></p>
                                <p><strong>Warranties and Representations Are Limited to the Fullest Extent of the Law</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION AND/OR LIMITATION OF IMPLIED REPRESENTATIONS, CONDITIONS OR WARRANTIES, OR ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH EVENT, VERNER&rsquo;S WARRANTIES, CONDITIONS AND REPRESENTATIONS WITH RESPECT TO THE WEBSITE, THE SERVICES, AND SOFTWARE WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW IN SUCH JURISDICTION.</p>
                                <p>&nbsp;</p>
                                <p><strong>Limitation of Liability</strong></p>
                                <p>&nbsp;</p>
                                <p>VCC, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS ASSUME NO RESPONSIBILITY WITH RESPECT TO YOU&rsquo;S OR YOUR END USER'S USE OF THE SERVICES AND WILL NOT BE LIABLE FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, PUNITIVE, EXTRAORDINARY, EXEMPLARY OR SPECIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF USE, BUSINESS INTERRUPTIONS, LOSS OF DATA, LOSS OF PROFITS, AND LOST REVENUE, WHETHER SUCH DAMAGES ARE ALLEGED IN TORT, CONTRACT OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT VERNER IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES. VERNER, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS WILL IN NO EVENT BE LIABLE IN AGGREGATE FOR MORE THAN THE TOTAL FEES ACTUALLY RECEIVED BY VERNER FROM YOU FOR THE SERVICES DURING THE 12-MONTH PERIOD BEFORE THE CLAIM OR CAUSE OF ACTION AROSE. MULTIPLE CLAIMS WILL NOT EXPAND THIS LIMITATION.&nbsp;&nbsp; IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE WEBSITE OR THE SERVICES FOUND AT THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE SUCH CAUSE OF ACTION WILL BE PERMANENTLY BARRED.&nbsp; SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CONSEQUENTIAL, INCIDENTAL, SPECIAL OR OTHER DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH EVENT, THE LIABILITY OF VERNER, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS FOR SUCH DAMAGES WITH RESPECT TO THE WEBSITE AND THE SERVICES WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, AND WILL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS AGREEMENT OR YOU&rsquo;S USE OF THE WEBSITE OR THE SERVICES FOUND AT THE WEBSITE.</p>
                                <p>&nbsp;</p>
                                <strong><br /> </strong>
                                <p><strong>&nbsp;</strong></p>
                                <p><strong>Indemnification of Verner</strong></p>
                                <p>&nbsp;</p>
                                <p>You agree to defend, indemnify and hold VCC and its Content Providers and the respective directors, officers, employees and agents of each harmless from and against any and all claims, losses, damages, liabilities and costs (including, without limitation, reasonable attorneys' fees and court costs) arising out of or relating to Your breach of any of these Terms or use by You or any third party (authorized, permitted or enabled by You) of the Services, except to the extent the foregoing directly result from Verner's own gross negligence or willful misconduct. Verner reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by You. THIS FOREGOING INDEMNIFICATION WILL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS AGREEMENT OR YOU&rsquo;RE USE OF THE WEBSITE OR THE SERVICES FOUND AT THE WEBSITE.</p>
                                <p>&nbsp;</p>
                                <p><strong>INFORMATION ABOUT YOU AND YOUR VISITS TO THE WEBSITE</strong></p>
                                <p><br /> VCC processes information about you in accordance with VCC&rsquo;s&nbsp;privacy policy.&nbsp; By using the Website, you consent to such processing and you warrant that all data provided by you is accurate.</p>
                                <p>&nbsp;</p>
                                <p><strong>VIRUSES, HACKING AND OTHER OFFENCES</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>You must not misuse the Website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorized access to the Website, the server on which the Website is stored or any server, computer or database connected to the Website. You must not attack the Website via a denial-of-service attack or a distributed denial-of service attack.</p>
                                <p>By breaching this provision, you may commit a criminal offence under the Computer Fraud and Abuse Act. VCC will report any such breach to the relevant law enforcement authorities and VCC will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the Website will cease immediately.</p>
                                <p>VCC will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Website or to your downloading of any material posted on it, or on any website linked to it.</p>
                                <p>&nbsp;</p>
                                <p><strong>LINKING TO THE WEBSITE</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>You may link to VCC&rsquo;s home page, provided you do so in a way that is fair and legal and does not damage VCC&rsquo;s reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on VCC&rsquo;s part where none exists.</p>
                                <p>You must not establish a link from any website that is not owned by you.</p>
                                <p>&nbsp;</p>
                                <p>The Website must not be framed on any other site, nor may you create a link to any part of the Website other than the home page. VCC reserve the right to withdraw linking permission without notice. The website from which you are linking must comply in all respects with the content standards set out in VCC&rsquo;s <a href="https://www.cole-and-son.com/en/terms/website-acceptable-use-policy/">acceptable use policy</a>.</p>
                                <p>&nbsp;</p>
                                <p>If you wish to make any use of material on the Website other than that set out above, please address your request to&nbsp;dan@VCCcc.com.</p>
                                <p>&nbsp;</p>
                                <p><strong>LINKS FROM THE WEBSITE</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>Where the Website contains links to other sites and resources provided by third parties, these links are provided for your information only.&nbsp; VCC has no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them.&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><strong>VARIATIONS</strong></p>
                                <p><br /> VCC may revise this Agreement at any time by amending this page. You are expected to check this page from time to time and before accessing or using the Website to take notice of any changes VCC made, as they are binding on you. Some of the provisions contained in these Agreement may also be superseded by provisions or notices published elsewhere on the Website.</p>
                                <p>&nbsp;</p>
                                <p><strong>COPYRIGHTS AND TRADEMARKS</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>Worldwide copyright laws and treaty provisions protect all materials appearing anywhere on the Website. None of the materials may be copied, reproduced, modified, or distributed in any form or by any means without a prior written permission.&nbsp; All material on the Website is copyright &copy; 2018 VCC its respective licensors.</p>
                                <p>&nbsp;</p>
                                <p>VCC&trade; and Return to Work Connections&trade;, are the exclusive trademarks of VCC.</p>
                                <p>&nbsp;</p>
                                <p>Any unauthorized use of the materials appearing on the Website may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties<strong>.</strong></p>
                                <p><strong>WAIVER</strong></p>
                                <p>&nbsp;</p>
                                <p>No waiver by any party hereto of any breach by the other party of any term, covenant or condition of this Agreement shall be deemed a waiver of any other breach (whether prior to or subsequent) of the same or any other term, covenant or condition of this or any other agreement.</p>
                                <p>&nbsp;</p>
                                <p><strong>BINDING EFFECT</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>This Agreement, and all rights and obligations hereunder, shall be binding upon, and inure to the benefit of the parties hereto and their respective heirs, executors, administrators, personal representatives, successors, licensees and permitted assigns.</p>
                                <p>&nbsp;</p>
                                <p><strong>PARAGRAPH HEADINGS</strong></p>
                                <p>&nbsp;</p>
                                <p>Paragraph headings used herein are for convenience only, and shall not be deemed to be part of this Agreement.</p>
                                <p>&nbsp;</p>
                                <p><strong>SEVERABILITY</strong></p>
                                <p>&nbsp;</p>
                                <p>If any provision in this Agreement is held by a court of competent jurisdiction to be invalid, void or unenforceable, the remaining provisions shall nevertheless continue in full force without being impaired or invalidated in any way.</p>
                                <p>&nbsp;</p>
                                <p><strong>NO PARTNERSHIP</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>Nothing herein contained shall constitute a partnership between, or joint venture by, the parties hereto, or constitute either party an agent of the other.&nbsp;&nbsp; Neither party shall hold itself out contrary to the terms of this paragraph and neither party shall become liable by any representation, act or omission of the other contrary to the provisions hereof.</p>
                                <p>&nbsp;</p>
                                <p><strong>ENTIRE AGREEMENT</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>This Agreement represents the entire agreement between the parties hereto and supersedes all prior representations, negotiations, promises, understandings or agreements, whether oral or written, between the parties with respect to the subject matter hereof.</p>
                                <p>&nbsp;</p>
                                <p><strong>AMENDMENTS</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>No amendment or modification of this Agreement or of any covenant, condition, or limitation herein contained shall be valid unless in writing and made by the party to be charged therewith.</p>
                                <p>&nbsp;</p>
                                <p><strong>REMEDIES CUMULATIVE</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>Except as may be expressly provided to the contrary herein, the parties' various rights and remedies hereunder shall be cumulative and the exercise or enforcement of any one or more of them shall not preclude the enforcing party from exercising or enforcing any of the others or any right or remedy provided for by law.</p>
                                <p>&nbsp;</p>
                                <p><strong>GOVERNING LAW</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>This Agreement is entered into and shall be deemed wholly performed in Miami-Dade County, Florida and shall in all respects be governed by and construed in accordance exclusively with the laws of the State of Florida applicable to contracts made and to be entirely performed therein without regard to principles of conflicts of law.&nbsp;&nbsp; The parties specifically agree that the state or federal courts in Miami-Dade County, Florida, shall have jurisdiction and exclusive venue in respect of any and all disputes in connection herewith.</p>
                                <p>&nbsp;</p>
                                <strong><br /> </strong>
                                <p><strong>&nbsp;</strong></p>
                                <p><strong>YOUR CONCERNS</strong></p>
                                <p><strong>&nbsp;</strong></p>
                                <p>If you have any concerns about material, which appears on the Website, please contact&nbsp;support@varzis.com.</p>
                                <p>Thank you for visiting the Website.</p>
                                <p>&nbsp;</p>
                            </div>
                        </section>
                    </div>
                </section>
                
            </Modal>

            </header>
	}

    agreeToTerms() {
        this.setState({ showTerms: false })
        request('projectAudit', {
            id: -1,
            action: "User agree to terms",
            userId: this.props.user ? this.props.user.id : 0,
            date: moment().valueOf()
        }).then((result: any) => {
        
        }).catch(() => {
           
        });
    }

	toggleMenu (event: React.MouseEvent<HTMLElement>): void {
		this.setState({
			isMenuOpen: !this.state.isMenuOpen
		});
	}
}

export default withRouter(connect<LoginState.LoginState, {}, any>(
	(state: ApplicationState) => state.login,
	{}
)(Header));
